import {
    faCircleXmark,
    faLock,
    faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { UserFileData } from '../../types';
import { UserFileType } from '../../types/user-file-types';

interface UserUploadButtonProps {
    title: string;
    onUpload: (file: File) => void;
    onRemove: (fileData: UserFileData) => void;
    disabled?: boolean;
    fileData?: UserFileData[];
    admin?: boolean;
    unlockUserData: (field?: string, fileUpload?: UserFileType) => void;
    type: UserFileType;
}

const UserUploadButton: FC<UserUploadButtonProps> = (props) => {
    const [disabled, setDisabled] = useState(props.disabled);
    const [fileData, setFileData] = useState<UserFileData[]>([]);

    useEffect(() => {
        if (props.fileData) {
            setFileData(props.fileData);
        }
    }, [props.fileData]);

    useEffect(() => {
        setDisabled(props.disabled);
    }, [props.disabled]);

    const onUpload = (file: File) => {
        props.onUpload(file);
    };

    return (
        <Box>
            <Box display="flex" flexDirection="row">
                <Button
                    variant="contained"
                    component="label"
                    disabled={disabled || fileData.length > 2 || props.admin}
                    fullWidth
                >
                    {props.title}
                    <input
                        type="file"
                        hidden
                        accept="application/pdf,.jpg,.png"
                        onClick={(e) => (e.currentTarget.value = '')}
                        onChange={(e) => {
                            e.target &&
                                e.target.files &&
                                onUpload(e.target.files[0]);
                        }}
                    />
                </Button>
                {props.admin && (
                    <Tooltip
                        title={
                            props.disabled
                                ? 'Datei entsperren'
                                : 'Datei sperren'
                        }
                    >
                        <IconButton
                            onClick={() =>
                                props.unlockUserData(undefined, props.type)
                            }
                            size="small"
                            disableRipple
                        >
                            <FontAwesomeIcon
                                icon={props.disabled ? faUnlock : faLock}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Box mt={1} display={fileData.length > 0 ? 'flex' : 'none'}>
                <Grid container>
                    {fileData.map((data, index) => (
                        <Grid
                            item
                            xs={12}
                            key={index}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <a
                                href={data.url}
                                target="_blank"
                                download={data.name}
                                rel="noreferrer"
                            >
                                {data.name}
                            </a>
                            <IconButton
                                onClick={() => props.onRemove(data)}
                                size="small"
                                color="error"
                                disableRipple
                                disabled={disabled}
                            >
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default UserUploadButton;
