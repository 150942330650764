import { faPaste } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ContractApi, UserApi } from '../../api';
import { Navbar } from '../../components';
import { AppContext } from '../../hooks/context';
import { Regions } from '../../types/region';
// import PdfViewerComponent from '../../components/pdf/pdf-viewer';
import { handleFileRead } from '../../utils/file-utils';
import { loadTitle, setDocumentTitle } from '../../utils/misc-utils';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';
import { useQuery, useQueryClient } from 'react-query';
import { ContractType } from '../../types/contract';

// Setup file reading
const reader = new FileReader();
reader.onload = handleFileRead;

interface PointsgameTypeStatus {
    activated: number;
    pending: number;
    missing: number;
    reachedPercentage: number;
    activatedReachedPercentage: number;
    minimum: number;
}

interface PointsgameStatusResponse {
    goalReached: boolean;
    landline: PointsgameTypeStatus;
    mobile: PointsgameTypeStatus;
    congstar: PointsgameTypeStatus;
}

const Upload = () => {
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [contractNumber, setContractNumber] = useState('');
    const [contractType, setContractType] = useState('');
    const [selectedContractFile, setSelectedContractFile] =
        useState<File | null>();
    const [signContractVisible, setSignContractVisible] = useState<any>(false);
    const [exporting, setExporting] = useState(false);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const [uploadedContractId, setUploadedContractId] = useState('');
    const [email, setEmail] = useState('');
    const [region, setRegion] = useState('');
    //const [comment, setComment] = useState('');

    const queryClient = useQueryClient();

    const [currentMonthFocusPartnerStatus, setCurrentMonthFocusPartnerStatus] =
        useState<PointsgameStatusResponse | undefined>(undefined);

    useQuery({
        enabled: context.authToken !== undefined,
        queryKey: ['currentMonthFocusPartnerStatus'],
        queryFn: () =>
            UserApi.getCurrentMonthFocusPartnerStatus(context.authToken),
        onSuccess: (data) => setCurrentMonthFocusPartnerStatus(data.data),
    });

    useEffect(() => {
        setDocumentTitle('Vertrag hochladen');
        return () => loadTitle();
    }, []);

    const contractNumberInputValid = (v: string) => {
        if (contractType === 'congstar' && /^\d+$/.test(v)) {
            return true;
        } else if (
            contractType === 'mobile' &&
            (/^KKM-+$/.test(v) || /^KKM-(\d|-)+$/.test(v))
        ) {
            return true;
        } else if (
            contractType === 'landline' &&
            (/^CRMT-\d+$/.test(v) || /^CRMT-+$/.test(v))
        ) {
            return true;
        }
        return false;
    };

    const inputContractType = (v: string) => {
        setContractType(v);
        setSelectedContractFile(undefined);
        setSignContractVisible(false);
        if (v === 'landline') {
            setContractNumber('CRMT-');
        } else if (v === 'mobile') {
            setContractNumber('KKM-');
        } else {
            setContractNumber('');
        }
    };

    const inputContractNumber = (v: string) => {
        v = v.trim().toUpperCase();
        if (v === '') {
            if (contractType === 'congstar') {
                setContractNumber('');
            } else if (contractType === 'mobile') {
                setContractNumber('KKM-');
            } else if (contractType === 'landline') {
                setContractNumber('CRMT-');
            }
        } else if (contractNumberInputValid(v)) {
            setContractNumber(v);
        }
    };

    const selectFile = (file: File) => {
        setSelectedContractFile(file);
    };

    const pasteText = async () => {
        let text = await navigator.clipboard.readText();
        if (text) {
            text = text.toUpperCase().trim();
            if (contractType === 'mobile' && !text.startsWith('KKM-')) {
                text = 'KKM-' + text;
            } else if (
                contractType === 'landline' &&
                !text.startsWith('CRMT-')
            ) {
                text = 'CRMT-' + text;
            }
            if (contractNumberInputValid(text)) {
                if (contractNumber !== text) {
                    setContractNumber(text);
                    snackbarUtils.info('Text wurde eingefügt');
                }
            } else {
                snackbarUtils.warning('Ungültiges Format');
            }
        }
    };

    const resetPage = () => {
        setContractNumber('');
        setContractType('');
        setRegion('');
        setSelectedContractFile(undefined);
        setSignContractVisible(false);
        setExporting(false);
    };

    const isValidEmail = () => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const saveEmail = async () => {
        if (email && uploadedContractId) {
            if (isValidEmail()) {
                setLoading(true);
                try {
                    const r = await ContractApi.persistEmail(
                        context.authToken,
                        {
                            contractNumber: uploadedContractId,
                            email,
                        },
                    );
                    if (r) {
                        setLoading(false);
                        setEmailDialogOpen(false);
                        setUploadedContractId('');
                        resetPage();
                    } else {
                        setLoading(false);
                    }
                } catch (e) {
                    setLoading(false);
                }
            } else {
                snackbarUtils.warning('E-Mail Adresse ungültig');
            }
        }
    };

    const onUpload = async () => {
        if (selectedContractFile) {
            if (context.isAdmin && !region) {
                snackbarUtils.warning(
                    'Mit Adminrechten muss die Region angegeben werden',
                );
                return;
            }
            setLoading(true);
            try {
                const r = await ContractApi.persistContract(
                    context.authToken,
                    Object.assign(
                        {
                            contractNumber: contractNumber,
                            contractFile: selectedContractFile,
                        },
                        region && { region },
                        //comment && { comment },
                    ),
                );
                if (r && r.warning && r.warning.type === 'email-missing') {
                    setSelectedContractFile(undefined);
                    setSignContractVisible(false);
                    setUploadedContractId(r.data.id);
                    setEmailDialogOpen(true);
                    setLoading(false);
                    setExporting(false);
                } else if (r) {
                    setLoading(false);
                    setExporting(false);
                    resetPage();
                } else {
                    setLoading(false);
                    setExporting(false);
                }
                queryClient.invalidateQueries('currentMonthFocusPartnerStatus');
            } catch (error) {
                console.error(error);
                setLoading(false);
                setExporting(false);
            }
        }
    };

    return (
        <Box>
            <Navbar title="Vertrag hochladen" />
            <Card
                sx={{
                    width: { xs: '100%', md: '50%' },
                    textAlign: 'center',
                    mr: 'auto',
                    ml: 'auto',
                }}
            >
                <CardContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                            justifyContent: 'top',
                            alignItems: 'center',
                        }}
                    >
                        <Typography gutterBottom variant="h5">
                            Vertrag hochladen
                        </Typography>
                        <Select
                            value={contractType}
                            sx={{ mt: 2, width: '22rem' }}
                            onChange={(e) => inputContractType(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem disabled value="">
                                Vertragstyp
                            </MenuItem>
                            <MenuItem value="landline">Festnetz</MenuItem>
                            <MenuItem value="mobile">Mobilfunk</MenuItem>
                            <MenuItem value="congstar">Congstar</MenuItem>
                        </Select>
                        <TextField
                            label="Auftragsnummer"
                            value={contractNumber}
                            disabled={
                                selectedContractFile !== undefined ||
                                (contractNumber.length === 0 &&
                                    contractType !== 'congstar')
                            }
                            onChange={(e) => {
                                inputContractNumber(e.currentTarget.value);
                            }}
                            sx={{ mt: 2, width: '22rem' }}
                            helperText="Muss im Format CRMT-xxxx oder KKM-xxxx sein"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            id="pasteBtn"
                                            onClick={() => pasteText()}
                                            disabled={contractType === ''}
                                        >
                                            <Tooltip title="Aus Zwischenablage einfügen">
                                                <FontAwesomeIcon
                                                    icon={faPaste}
                                                />
                                            </Tooltip>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <TextField
                            label="Kommentar"
                            value={comment}
                            onChange={(e) => {
                                setComment(e.currentTarget.value);
                            }}
                            sx={{ mt: 2, width: '22rem' }}
                        /> */}
                        {context.isAdmin && (
                            <FormControl sx={{ width: '22rem', mt: 1 }}>
                                <InputLabel>Region</InputLabel>
                                <Select
                                    value={region}
                                    label="Region"
                                    onChange={(e) => setRegion(e.target.value)}
                                >
                                    {Object.keys(Regions)
                                        .filter((r) => {
                                            if (context.isSuperadmin) {
                                                return true;
                                            } else if (
                                                context.region?.includes(r)
                                            ) {
                                                return true;
                                            }
                                            return false;
                                        })
                                        .map((s) => {
                                            return (
                                                <MenuItem key={s} value={s}>
                                                    <ListItemText
                                                        primary={Regions[s]}
                                                    />
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        )}
                        <Button
                            variant="contained"
                            component="label"
                            disabled={
                                !contractNumberInputValid(contractNumber) ||
                                (context.isAdmin && !region)
                            }
                            sx={{ mt: 2, width: '22rem' }}
                        >
                            Vertrag auswählen
                            <input
                                type="file"
                                hidden
                                accept="application/pdf"
                                onClick={(e) => (e.currentTarget.value = '')}
                                onChange={(e) => {
                                    e.target &&
                                        e.target.files &&
                                        selectFile(e.target.files[0]);
                                    setSignContractVisible(
                                        e.target !== undefined,
                                    );
                                }}
                            />
                        </Button>
                        <Box sx={{ height: '3rem', mt: 1 }}>
                            {selectedContractFile && (
                                <span>{selectedContractFile.name}</span>
                            )}
                        </Box>
                        {selectedContractFile && (
                            <Button
                                color="error"
                                variant="contained"
                                id="submitBtn"
                                className="formBtn"
                                sx={{
                                    mb: 2,
                                    width: '22rem',
                                    display: exporting ? 'none' : 'inline-flex',
                                }}
                                onClick={() => resetPage()}
                            >
                                Verwerfen
                            </Button>
                        )}
                        {signContractVisible && (
                            <>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    id="exportBTN"
                                    className="formBtn"
                                    sx={{
                                        mb: 2,
                                        width: '22rem',
                                        display: exporting
                                            ? 'none'
                                            : 'inline-flex',
                                    }}
                                    onClick={() => {
                                        onUpload();
                                        // setExporting(true);
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress
                                            sx={{ color: 'white' }}
                                        />
                                    ) : (
                                        'Hochladen'
                                    )}
                                </Button>
                                {/* <div
                                    style={{
                                        width: '100%',
                                        height: '100vh',
                                        display: exporting ? 'none' : 'block',
                                    }}
                                >
                                    <PdfViewerComponent
                                        exporting={exporting}
                                        contractNumber={contractNumber}
                                        contractType={contractType}
                                        exportFinished={(success = true) => {
                                            setLoading(false);
                                            setExporting(false);
                                            if (success) {
                                                resetPage();
                                            }
                                        }}
                                        openEmailDialog={() => {
                                            setSelectedContractFile(undefined);
                                            setSignContractVisible(false);
                                            setEmailDialogOpen(true);
                                            setLoading(false);
                                            setExporting(false);
                                        }}
                                        document={
                                            selectedContractFile
                                                ? window.URL.createObjectURL(
                                                      selectedContractFile,
                                                  )
                                                : ''
                                        }
                                    />
                                </div> */}
                                {exporting && <CircularProgress />}
                            </>
                        )}
                    </Box>
                    {currentMonthFocusPartnerStatus && (
                        <>
                            {currentMonthFocusPartnerStatus.goalReached ? (
                                <Box sx={{ mb: '5px' }}>✅ Fokuspartner</Box>
                            ) : (
                                <>
                                    <Typography>
                                        <b>Fokuspartner Status</b>
                                    </Typography>
                                    <br />
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        style={{
                                            width: '70%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                        justifyContent="space-between"
                                    >
                                        {['landline', 'congstar', 'mobile'].map(
                                            (t) => {
                                                const current =
                                                    currentMonthFocusPartnerStatus[
                                                        t
                                                    ];
                                                return (
                                                    <Box key={t} sx={{ pb: 2 }}>
                                                        <Box
                                                            sx={{
                                                                mb: '5px',
                                                                fontSize: '1em',
                                                            }}
                                                        >
                                                            {current.missing > 0
                                                                ? `Noch ${
                                                                      current.missing
                                                                  } ${
                                                                      ContractType[
                                                                          t.toUpperCase()
                                                                      ]
                                                                  } Verträge`
                                                                : `${
                                                                      ContractType[
                                                                          t.toUpperCase()
                                                                      ]
                                                                  } Ziel erreicht`}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            flexDirection="row"
                                                        >
                                                            <LinearProgress
                                                                variant="buffer"
                                                                value={
                                                                    current.activatedReachedPercentage
                                                                }
                                                                valueBuffer={
                                                                    current.reachedPercentage
                                                                }
                                                                sx={{
                                                                    '& .MuiLinearProgress-dashed':
                                                                        {
                                                                            animation:
                                                                                'none',
                                                                            backgroundImage:
                                                                                'none',
                                                                            backgroundColor:
                                                                                '#eaeaea',
                                                                            left: 0,
                                                                        },
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box sx={{ mt: '5px' }}>
                                                            ({current.activated}{' '}
                                                            aktiviert,{' '}
                                                            {current.pending}{' '}
                                                            ausstehend)
                                                        </Box>
                                                    </Box>
                                                );
                                            },
                                        )}
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                </CardContent>
            </Card>
            <Dialog open={emailDialogOpen}>
                <DialogTitle>E-Mail fehlt</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Auf dem Vertrag konnte keine E-Mail gefunden werden.
                        Bitte tragen Sie diese ein!
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="E-Mail Adresse des Kunden"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value.trim())}
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setLoading(false);
                            setEmailDialogOpen(false);
                            setUploadedContractId('');
                            resetPage();
                        }}
                    >
                        Keine E-Mail Adresse vorhanden
                    </Button>
                    {!loading ? (
                        <Button onClick={saveEmail} disabled={!email}>
                            Speichern
                        </Button>
                    ) : (
                        <CircularProgress sx={{ color: 'primary' }} />
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Upload;
