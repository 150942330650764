import { Box, IconButton, Link, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Navbar } from '../../components';
import { AppContext } from '../../hooks/context';
import {
    loadTitle,
    setDocumentTitle,
    useURLQuery,
} from '../../utils/misc-utils';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import {
    ContractDoc,
    ContractStatus,
    ContractType,
} from '../../types/contract';
import {
    convertDateToFormattedString,
    convertISOStringToDate,
} from '../../utils/date-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye,
    faFilePdf,
    faLink,
    faPenToSquare,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import FilterToolbar from '../../components/datagrid/filter-toolbar';
import moment from 'moment-timezone';
import { Regions } from '../../types/region';
import { ContractApi } from '../../api';
import DeleteContractDialog from '../../components/delete-contract-dialog/delete-contract-dialog';

interface DataGridElement {
    id: string;
    contract: ContractDoc;
    contractNumber: string;
    orderNumber: string;
    type: string;
    status: ContractStatus;
    combinedName: string;
    firstName: string;
    lastName: string;
    email: string;
    creator: string;
    creatorDisplayName: string;
    creationDate: Date;
    region?: string;
}

const fieldLabels = {
    contractNumber: 'Nummer',
    type: 'Typ',
    status: 'Status',
    name: 'Name',
    email: 'E-Mail',
    region: 'Region',
    creator: 'Ersteller',
    created: 'Erstelldatum',
};

const Contracts = () => {
    const { contractType } = useParams();
    const urlQuery = useURLQuery();
    const queryStatusFilter = urlQuery.get('status');
    const queryRegionFilter = urlQuery.get('region');
    const context = useContext(AppContext);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<DataGridElement[]>([]);
    const [totalRowCount, setTotalRowCount] = useState(10);

    const [fromDate, setFromDate] = useState<moment.Moment>(
        moment().subtract(1, 'month').startOf('month'),
    );
    const [toDate, setToDate] = useState<moment.Moment>(moment());
    const [showAll, setShowAll] = useState(false);
    const [showMy, setShowMy] = useState(false);
    const [statusFilter, setStatusFilter] = useState<string[]>(
        queryStatusFilter ? queryStatusFilter.split(',') : [],
    );
    const [searchText, setSearchText] = useState('');
    const [regionFilter, setRegionFilter] = useState(queryRegionFilter || '');
    const [userFilter, setUserFilter] = useState<
        { username: string; id: string }[]
    >([]);

    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const [sortModel, setSortModel] = useState<GridSortModel>([
        { field: 'creationDate', sort: 'desc' },
    ]);
    const navigate = useNavigate();

    const [contractToDelete, setContractToDelete] = useState<string>();

    const { isLoading, error, data } = useQuery({
        queryKey: [
            'getContracts',
            fromDate,
            toDate,
            showAll,
            statusFilter,
            searchText,
            contractType,
            regionFilter,
            pageNum,
            pageSize,
            sortModel,
            showMy,
            userFilter,
        ],
        queryFn: () => {
            const data: any = {
                pageNum,
                pageSize,
                sortModel,
            };
            if (!showAll) {
                data.fromDate = fromDate.toISOString();
                data.toDate = toDate.toISOString();
            } else {
                data.showAll = true;
            }
            data.showMy = showMy;
            if (regionFilter) {
                data.region = regionFilter;
            }
            if (userFilter && userFilter.length > 0) {
                data.userIds = userFilter.map((u) => u.id);
            }
            if (statusFilter && statusFilter.length > 0)
                data.status = statusFilter;
            if (searchText) data.searchText = searchText;
            if (contractType) {
                data.type = contractType;
            }
            return ContractApi.getContracts(context.authToken, data);
        },
        enabled: context.authToken !== undefined,
    });

    useEffect(() => {
        if (context.authToken) {
            setColumns([
                {
                    field: 'contractNumber',
                    headerName: fieldLabels.contractNumber,
                    editable: false,
                    flex: 1,
                    renderCell: (params) => (
                        <span>
                            {params.row.type === 'LANDLINE'
                                ? params.row.orderNumber
                                : params.row.contractNumber}
                        </span>
                    ),
                },
                {
                    field: 'type',
                    headerName: fieldLabels.type,
                    editable: false,
                    flex: 1,
                    valueFormatter: (params) => ContractType[params?.value],
                },
                {
                    field: 'status',
                    headerName: fieldLabels.status,
                    editable: false,
                    flex: 1,
                    valueFormatter: (params) => ContractStatus[params?.value],
                },
                {
                    field: 'name',
                    headerName: fieldLabels.name,
                    editable: false,
                    sortable: false,
                    renderCell: (params) => (
                        <span>
                            {params.row.firstName && params.row.lastName
                                ? `${params.row.firstName} ${params.row.lastName}`
                                : params.row.combinedName}
                        </span>
                    ),
                    flex: 1,
                },
                {
                    field: 'email',
                    headerName: fieldLabels.email,
                    sortable: false,
                    editable: false,
                    flex: 1,
                },
                {
                    field: 'region',
                    headerName: fieldLabels.region,
                    editable: false,
                    flex: 1,
                    hide: !context.isAdmin,
                    valueFormatter: (params) => Regions[params?.value],
                },
                {
                    field: 'creator',
                    headerName: fieldLabels.creator,
                    editable: false,
                    flex: 1,
                    renderCell: (params) =>
                        params.row.creatorDisplayName || params.row.creator,
                },
                {
                    field: 'creationDate',
                    headerName: fieldLabels.created,
                    width: 250,
                    editable: false,
                    flex: 1,
                    valueFormatter: (params) =>
                        convertDateToFormattedString(params?.value),
                },
                {
                    field: 'Aktionen',
                    headerName: '',
                    hideable: false,
                    sortable: false,
                    flex: 1,
                    renderCell: (params) => {
                        const contractId = params.id.toString();
                        const contractUrl = `/contract/${contractId}${
                            contractType ? `/${contractType}` : ''
                        }${
                            statusFilter.length > 0
                                ? `?status=${statusFilter.join(',')}`
                                : ''
                        }${
                            statusFilter.length > 0
                                ? '&'
                                : regionFilter
                                ? '?'
                                : ''
                        }${regionFilter ? `region=${regionFilter}` : ''}`;
                        return (
                            <Box>
                                <Link
                                    href={contractUrl}
                                    onClick={() => navigate(contractUrl)}
                                >
                                    <IconButton
                                        disableRipple
                                        color="info"
                                        sx={{ height: 39 }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                context.isAdmin ||
                                                params.row.contract.status ===
                                                    ContractStatus.INQUIRY
                                                    ? faPenToSquare
                                                    : faEye
                                            }
                                        />
                                    </IconButton>
                                </Link>
                                {params.row.contract.contractUrl && (
                                    <Link
                                        href={params.row.contract.contractUrl}
                                        target="_blank"
                                    >
                                        <IconButton
                                            disableRipple
                                            color="info"
                                            sx={{ height: 39 }}
                                        >
                                            <FontAwesomeIcon icon={faFilePdf} />
                                        </IconButton>
                                    </Link>
                                )}
                                {context.isSuperadmin && (
                                    <IconButton
                                        disableRipple
                                        color="error"
                                        sx={{ height: 39 }}
                                        onClick={() =>
                                            setContractToDelete(
                                                params.row.contract
                                                    .contractNumber,
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </IconButton>
                                )}
                            </Box>
                        );
                    },
                },
            ]);
        }
    }, [context, contractType, statusFilter, regionFilter, navigate]);

    useEffect(() => {
        setDocumentTitle('Verträge');
        return () => loadTitle();
    }, []);

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data && data.data.contracts) {
            const r: DataGridElement[] = [];
            data.data.contracts.forEach((doc) => {
                r.push({
                    id: doc.contractNumber,
                    contract: doc,
                    contractNumber: doc.contractNumber,
                    orderNumber: doc.orderNumber,
                    type: doc.type || '',
                    status: doc.status,
                    combinedName: doc.combinedName,
                    firstName: doc.firstname,
                    lastName: doc.lastname,
                    email: doc.email,
                    creator: doc.creator,
                    creatorDisplayName: doc.creatorDisplayName,
                    creationDate: convertISOStringToDate(doc.created),
                    region: doc.region,
                });
            });
            if (data.data.orderBy) {
                const sModel: GridSortModel = data.data.orderBy.map((m) => {
                    const field: any = Object.keys(m)[0];
                    const sort: any = Object.values(m)[0];
                    if (field === 'creator') {
                        return {
                            field,
                            sort: sort?.username,
                        };
                    }
                    return {
                        field,
                        sort,
                    };
                });
                setSortModel(sModel);
            }
            setRows(r);
            setTotalRowCount(data.data.contractCount);
        }
    }, [data, error]);

    const openShownContractsInNewTab = () => {
        rows.forEach((r) => {
            const url = `${window.origin}/contract/${r.id}`;
            window.open(url, '_blank');
        });
    };

    return (
        <Box>
            <Navbar title="Verträge" />
            <div style={{ height: '100%' }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    loading={!context.user || isLoading}
                    getRowId={(row) => row.id}
                    rowCount={totalRowCount}
                    components={{
                        Toolbar: FilterToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            fromDate,
                            setFromDate,
                            toDate,
                            setToDate,
                            showAll,
                            setShowAll,
                            statusFilter,
                            setStatusFilter,
                            searchText,
                            setSearchText,
                            regionFilter,
                            setRegionFilter,
                            showMy,
                            setShowMy,
                            userFilter,
                            setUserFilter,
                        },
                    }}
                    // Pagination settings
                    paginationMode="server"
                    page={pageNum}
                    onPageChange={(newPageNum) => setPageNum(newPageNum)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pageSize={pageSize}
                    // End Pagination settings
                    // Sorting settings
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    sortingOrder={['desc', 'asc']}
                    // End sorting settings
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                    autoHeight
                />
                <Tooltip title="Alle Verträge auf dieser Seite im neuen Tab öffnen">
                    <IconButton
                        disableRipple
                        color="info"
                        sx={{ height: 39 }}
                        onClick={() => openShownContractsInNewTab()}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </IconButton>
                </Tooltip>
            </div>
            <DeleteContractDialog
                open={contractToDelete !== undefined}
                contractToDelete={contractToDelete}
                onClose={() => setContractToDelete(undefined)}
            />
        </Box>
    );
};

export default Contracts;
