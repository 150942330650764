import { axiosInstance } from '../../utils/api/api-utils';

export default class AdminApi {
    static getUsers(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/admin/users', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getFPUsers(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/admin/users/fplist', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static createUser(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/admin/users/user', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static editUser(
        token: string | undefined,
        userId: string,
        body: any,
    ): Promise<any> {
        return axiosInstance
            .post(`/api/admin/users/user/${userId}`, body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }
}
