import { createRoot } from 'react-dom/client';
import { useContext, useEffect, useCallback } from 'react';
import { Blocker, History } from 'history';
import {
    UNSAFE_NavigationContext as NavigationContext,
    Navigator,
} from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../utils/misc-utils';
import DiscardDialog from '../components/discard-dialog/discard-dialog';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
export function useBlocker(blocker: Blocker, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const unblock = (navigator as Navigator & Pick<History, 'block'>).block(
            (tx) => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };

                blocker(autoUnblockingTx);
            },
        );

        return unblock;
    }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  when
 * @param saveChanges
 */
export function useSavePrompt(when = true) {
    const blocker = useCallback(
        (tx) => {
            const element = document.createElement('div');
            element.setAttribute('id', 'prompt-dialog-container');
            element.setAttribute('aria-hidden', 'true');

            const closePrompt = (state: boolean) => {
                if (element) {
                    root.unmount();
                }
                if (!state) {
                    document.body.removeChild(element);
                } else {
                    tx.retry();
                }
            };

            const root = createRoot(element!);
            document.body.appendChild(element);

            root.render(
                <ThemeProvider theme={theme}>
                    <DiscardDialog
                        open={when}
                        onDiscard={() => closePrompt(true)}
                        onClose={() => closePrompt(false)}
                    />
                </ThemeProvider>,
            );
        },
        [when],
    );

    useBlocker(blocker, when);
}
