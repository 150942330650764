export enum ContractStatus {
    NEW = 'NEU',
    //COMMENT_NEW = "NEU (Kommentar)",
    ACTIVATED = 'AKTIVIERT',
    DENIED = 'ABGELEHNT',
    INQUIRY = 'RÜCKFRAGE',
    REWORK = 'NACHARBEIT',
    CANCELED = 'STORNIERT',
    FOLLOWUP = 'WIEDERVORLAGE',
    INQUIRY_COMPLETED = 'RÜCKFRAGE BEANTWORTET',
    PROVIDER_CHANGE = 'ANBIETERWECHSEL',
    IN_PROGRESS = 'IN BEARBEITUNG',
    TERMINAL = 'ENDGERÄT',
    CONGSTAR_PREPAID = 'CONGSTAR PREPAID',
    ADDITIONAL_BOOKING_OPTION = 'ZUBUCHOPTION'
}

export enum ContractType {
    LANDLINE = 'Festnetz',
    MOBILE = 'Mobilfunk',
    CONGSTAR = 'Congstar',
}

export interface ContractData {
    orderNr: string;
    orderDate: string;
    customerNr: string;
    executionDate: string;
    salutation: string;
    firstName: string;
    lastName: string;
    street: string;
    location: string;
    email: string;
    phone: string;
    callbackPhone: string;
    type: string;
    inquiry: string;
    inquiryAnswer: string;
}

export interface ContractDoc extends Partial<ContractData> {
    creatorId: string;
    creator: string;
    contractNumber: string;
    path: string;
    status: ContractStatus;
    created: string;
    contractUrl?: string;
    creatorDisplayName?: string;
    messages: any[];
    protocols: any[];
}

export enum ProtocolTypes {
    CREATED = 'erstellt',
    STATUS_CHANGED = 'Status geändert',
    DATA_CHANGED = 'Daten geändert',
    CUSTOMER_EMAIL_SENT = 'Vertrag an Kunden verschickt',
}
