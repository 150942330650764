import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import NewsApi from '../../api/news/news';
import { AppContext } from '../../hooks/context';
import News from './news';

const NewsFeed: FC = () => {
    const context = useContext(AppContext);
    const [newsData, setNewsData] = useState([]);

    const { isLoading, error, data } = useQuery({
        queryKey: ['getNews'],
        queryFn: () => NewsApi.getNews(context.authToken),
        enabled: context.authToken !== undefined,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data) {
            setNewsData(data.data);
        }
    }, [data, error]);

    return (
        <Box
            display="flex"
            alignSelf="center"
            // maxWidth={(window.screen.width / 3) * 2}
            sx={{
                mt: 2,
                mb: 2,
                overflowX: 'auto',
            }}
            flexDirection="column"
        >
            {isLoading ? (
                <CircularProgress />
            ) : (
                <span>
                    {newsData.length === 0 ? (
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                            Keine News vorhanden
                        </Typography>
                    ) : (
                        newsData.map((news: any) => (
                            <News key={news.id} {...news} />
                        ))
                    )}
                </span>
            )}
        </Box>
    );
};

export default NewsFeed;
