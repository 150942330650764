import { Dialog, DialogContent } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { UserApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { UserFileData } from '../../../types';
import DiscardDialog from '../../discard-dialog/discard-dialog';
import UserData, { UserDataType } from '../../user-data/user-data';

interface UserDataDialogProps {
    id: string;
    open: boolean;
    onClose: () => void;
}

const UserDataDialog: FC<UserDataDialogProps> = (props) => {
    const context = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [discardOpen, setDiscardOpen] = useState(false);
    const [userData, setUserData] = useState<Partial<UserDataType>>({});
    const [fileData, setFileData] = useState<{ [key: string]: UserFileData[] }>(
        {},
    );
    const [blocked, setBlocked] = useState(false);
    const queryClient = useQueryClient();

    const { error, data } = useQuery({
        queryKey: ['getUserData'],
        queryFn: () => UserApi.getUserData(context.authToken, props.id),
        enabled: context.authToken !== undefined && open,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (
            data &&
            data.data &&
            data.data.userData &&
            data.data.fileData
        ) {
            setUserData(data.data.userData);
            setFileData(data.data.fileData);
        }
    }, [data, error]);

    useEffect(() => {
        setOpen(props.open);
        setDiscardOpen(false);
    }, [props.open]);

    const onClose = () => {
        if (blocked) {
            setDiscardOpen(true);
        } else {
            queryClient.invalidateQueries('getUserData');
            setUserData({});
            setFileData({});
            props.onClose();
        }
    };

    return (
        <Dialog fullWidth maxWidth={'md'} open={open} onClose={onClose}>
            <DialogContent sx={{ textAlign: 'center' }}>
                <UserData
                    userData={userData}
                    fileData={fileData}
                    admin
                    id={props.id}
                    onBlock={(blocked) => setBlocked(blocked)}
                />
            </DialogContent>
            <DiscardDialog
                open={discardOpen}
                onDiscard={() => props.onClose()}
                onClose={() => setDiscardOpen(false)}
            />
        </Dialog>
    );
};

export default UserDataDialog;
