import { createBrowserHistory } from 'history';
import { initializeApp } from 'firebase/app';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

export const appHistory = createBrowserHistory();
export const authCookieName = 'auth';
export const hideOnSmallScreen = {
    xs: 'none',
    sm: 'none',
    md: 'inline-flex',
    lg: 'inline-flex',
    xl: 'inline-flex',
};
export const showOnlySmallScreen = {
    xs: 'inline-flex',
    sm: 'inline-flex',
    md: 'none',
    lg: 'none',
    xl: 'none',
};
export const titleSuffix = 'Con-Corp GmbH';
export const isLocalhost = window.location.hostname === 'localhost';
export const firebaseConfig = {
    apiKey: 'AIzaSyDabfEGfHY07ZUkKLre-fq5-okMR_d_nNY',
    authDomain: 'marco-programm.firebaseapp.com',
    projectId: 'marco-programm',
    storageBucket: isLocalhost
        ? 'gs://marco-programm.appspot.com'
        : 'gs://con-corp',
    appId: '1:581441998761:web:c71cc8cc574f1a17b87eb8',
};

// // Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp);
// export const auth = getAuth(firebaseApp);
// (async () => {
//     await setPersistence(auth, browserLocalPersistence);
// })();

// export const firebaseFunctions = getFunctions(firebaseApp, 'europe-west3');

if (window.location.hostname === 'localhost') {
    // connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    connectStorageEmulator(firebaseStorage, '127.0.0.1', 9199);
    // connectFunctionsEmulator(firebaseFunctions, '127.0.0.1', 5001);
}

export const pspdfkitToolbarItems: any[] = [
    { type: 'sidebar-thumbnails' },
    { type: 'sidebar-document-outline' },
    { type: 'sidebar-annotations' },
    { type: 'sidebar-bookmarks' },
    { type: 'pager' },
    { type: 'zoom-out' },
    { type: 'zoom-in' },
    { type: 'zoom-mode' },
    { type: 'spacer' },
    { type: 'signature' },
    { type: 'export-pdf' },
    { type: 'print' },
];
