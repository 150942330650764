import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface ContactPopupProps {
    open: boolean;
    onClose: () => void;
    onSend: (message: string) => void;
}

const ContactPopup: FC<ContactPopupProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        setOpen(props.open);
        setMessage('');
    }, [props.open]);

    return (
        <Dialog fullWidth open={open} onClose={props.onClose}>
            <DialogTitle>Anmerkung zum Vertrag übermitteln</DialogTitle>
            <DialogContent>
                <TextField
                    multiline
                    placeholder="Nachricht"
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    InputProps={Object.assign({ style: { height: 100 } })}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="info"
                    variant="contained"
                    className="formBtn"
                    sx={{
                        width: '22rem',
                        flex: 1,
                        m: 2,
                    }}
                    onClick={() => props.onSend(message)}
                >
                    Absenden
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContactPopup;
