import { FC, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const AdminRoute: FC = () => {
    const context = useContext(AppContext);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     console.log(context.userDocLoading, context.userLoading, context.isAdmin)
    //     if (!context.userDocLoading && !context.userLoading && !context.isAdmin) {
    //         navigate('/');
    //     }
    // }, [context.userDocLoading, context.userLoading, context.isAdmin, navigate]);

    return context.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
