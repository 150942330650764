import {
    faAddressBook,
    faPenToSquare,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AdminApi } from '../../../api';
import { Navbar, UserDataDialog } from '../../../components';
import CreateEditUserDialog, {
    User,
} from '../../../components/admin/create-edit-user-dialog/create-edit-user-dialog';
import UserFilterToolbar from '../../../components/datagrid/user-filter-toolbar';
import { AppContext } from '../../../hooks/context';
import { Regions } from '../../../types/region';
import { RoleMapping } from '../../../types/role';
import { loadTitle, setDocumentTitle } from '../../../utils/misc-utils';

interface DataGridElement {
    id: string;
    username: string;
    isAdmin: boolean;
}

const UserList = () => {
    const context = useContext(AppContext);
    const [createEditUserOpen, setCreateEditUserOpen] = useState(false);
    const [rows, setRows] = useState<DataGridElement[]>([]);
    const [totalRowCount, setTotalRowCount] = useState(10);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [UserDataOpen, setUserDataOpen] = useState(false);
    const [userId, setUserId] = useState<any>('');
    const queryClient = useQueryClient();

    const [searchText, setSearchText] = useState('');
    const [regionFilter, setRegionFilter] = useState('');
    const [showInactiveUsers, setShowInactiveUsers] = useState<boolean>(false);
    const [roleFilter, setRoleFilter] = useState('');

    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const [sortModel, setSortModel] = useState<GridSortModel>([
        { field: 'username', sort: 'desc' },
    ]);

    useEffect(() => {
        setDocumentTitle('Benutzerverwaltung');
        return () => loadTitle();
    }, []);

    const { isLoading, error, data } = useQuery({
        queryKey: [
            'getUsers',
            searchText,
            regionFilter,
            showInactiveUsers,
            roleFilter,
            pageNum,
            pageSize,
            sortModel,
        ],
        queryFn: () => {
            const data: any = {
                pageNum,
                pageSize,
                sortModel,
            };
            if (searchText) {
                data.searchText = searchText;
            }

            if (regionFilter) {
                data.region = regionFilter;
            }

            if (showInactiveUsers) {
                data.showInactiveUsers = showInactiveUsers;
            }

            if (roleFilter) {
                data.role = roleFilter;
            }

            return AdminApi.getUsers(context.authToken, data);
        },
        enabled: context.authToken !== undefined && context.isAdmin,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data && data.data.users) {
            setRows(data.data.users);
            setTotalRowCount(data.data.userCount);
        }
    }, [data, error]);

    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Benutzer',
            editable: false,
            flex: 1,
            renderCell: (params) => {
                if (params.row.displayName) {
                    return `${params.row.displayName} (${params.row.username})`;
                }
                return params.row.username;
            },
        },
        {
            field: 'region',
            headerName: 'Region',
            editable: false,
            flex: 1,
            valueFormatter: (params) =>
                Array.isArray(params?.value)
                    ? params.value.map((r) => Regions[r]).join(', ')
                    : Regions[params?.value],
        },
        {
            field: 'role',
            headerName: 'Rolle',
            editable: false,
            flex: 1,
            valueFormatter: (params) => RoleMapping[params?.value],
        },
        {
            field: 'active',
            headerName: 'Aktiviert',
            editable: false,
            flex: 1,
            valueFormatter: (params) => (params?.value ? 'JA' : 'NEIN'),
        },
        // {
        //     field: 'isComplete',
        //     headerName: 'Daten vollständig',
        //     editable: false,
        //     sortable: false,
        //     hide: !context.isSuperadmin,
        //     flex: 1,
        //     valueFormatter: (params) => (params?.value ? 'JA' : 'NEIN'),
        // },
        {
            field: 'customerId',
            headerName: 'VP-Nummer',
            editable: false,
            sortable: true,
            hide: !context.isSuperadmin,
            flex: 1,
        },
        {
            field: 'Aktionen',
            headerName: '',
            flex: 1,
            hideable: false,
            sortable: false,
            renderHeader: () => (
                <Tooltip title="Benutzer erstellen">
                    <IconButton
                        disableRipple
                        color="primary"
                        onClick={() => setCreateEditUserOpen(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            ),
            renderCell: (params) => (
                <Box>
                    <IconButton
                        disableRipple
                        color="info"
                        sx={{ height: 39 }}
                        onClick={() => {
                            setSelectedUser(params.row);
                            setCreateEditUserOpen(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </IconButton>
                    {/* {context.isSuperadmin && (
                        <IconButton
                            disableRipple
                            color="info"
                            sx={{ height: 39 }}
                            onClick={() => {
                                setUserDataOpen(true);
                                setUserId(params.id);
                            }}
                        >
                            <Badge
                                color="warning"
                                variant="dot"
                                invisible={!params.row.dataChanged}
                            >
                                <FontAwesomeIcon icon={faAddressBook} />
                            </Badge>
                        </IconButton>
                    )} */}
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Navbar title="Benutzerverwaltung" />
            <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    rowCount={totalRowCount}
                    components={{
                        Toolbar: UserFilterToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            searchText,
                            setSearchText,
                            regionFilter,
                            setRegionFilter,
                            roleFilter,
                            setRoleFilter,
                            showInactiveUsers,
                            setShowInactiveUsers,
                        },
                    }}
                    // Pagination settings
                    // paginationMode="server"
                    page={pageNum}
                    onPageChange={(newPageNum) => setPageNum(newPageNum)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pageSize={pageSize}
                    // End Pagination settings
                    // Sorting settings
                    // sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    sortingOrder={['desc', 'asc']}
                    // End sorting settings
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                    autoHeight
                />
            </Box>
            <CreateEditUserDialog
                open={createEditUserOpen}
                onClose={() => {
                    setCreateEditUserOpen(false);
                    setSelectedUser(undefined);
                }}
                isEdit={selectedUser ? true : false}
                existingUser={selectedUser}
            />
            <UserDataDialog
                open={UserDataOpen}
                onClose={() => {
                    setUserDataOpen(false);
                    queryClient.invalidateQueries('getUsers');
                }}
                id={userId}
            />
        </Box>
    );
};

export default UserList;
