import { axiosInstance } from '../../utils/api/api-utils';

export default class UserApi {
    static getStatus(
        token: string | undefined,
        monthSubtract: number = 0,
    ): Promise<any> {
        return axiosInstance
            .get(`/api/user/status/${monthSubtract}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getPermittedUsersStatus(
        token: string | undefined,
        monthSubtract: number = 0,
        region?: string,
    ): Promise<any> {
        return axiosInstance
            .get(
                `/api/user/users/status/${monthSubtract}${
                    region ? `/${region}` : ''
                }`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                },
            )
            .then((res) => {
                return res.data;
            });
    }

    static updateUserData(
        token: string | undefined,
        userId: string | undefined,
        body: any,
    ): Promise<any> {
        return axiosInstance
            .post(`/api/user/update/${userId}`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getUserData(
        token: string | undefined,
        userId: string | undefined,
    ): Promise<any> {
        return axiosInstance
            .get(`/api/user/data/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getPermittedUsers(token: string | undefined, includeInactive?: boolean): Promise<any> {
        return axiosInstance
            .get(`/api/user/users${includeInactive ? '/includeInactive' : ''}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static unlockUserDataFields(
        token: string | undefined,
        userId: string | undefined,
        body: any,
    ) {
        return axiosInstance
            .post(`/api/user/data/${userId}/unlock`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static isDataComplete(token: string | undefined): Promise<any> {
        return axiosInstance
            .get(`/api/user/data-complete`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getCurrentMonthFocusPartnerStatus(
        token: string | undefined,
    ): Promise<any> {
        return axiosInstance
            .get(`/api/user/focusPartnerStatus`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }
}
