import { FC, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from '../hooks/context';
import { authCookieName } from '../utils/constants';
import { getCookie } from '../utils/cookie/cookie-utils';

const UnauthenticatedRoute: FC = () => {
    const context = useContext(AppContext);
    const isAuthenticated = getCookie(authCookieName)
        ? context.isAuthenticated
        : false;

    return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

export default UnauthenticatedRoute;
