import {
    faPlus,
    faPenToSquare,
    faTrash,
    faEyeSlash,
    faEye,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import NewsApi from '../../../api/news/news';
import { Navbar, NewsDialog } from '../../../components';
import { AppContext } from '../../../hooks/context';
import {
    setDocumentTitle,
    loadTitle,
    parseDate,
} from '../../../utils/misc-utils';

const NewsList: FC = () => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    const [rows, setRows] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(10);
    const [open, setOpen] = useState(false);
    const [newsData, setNewsData] = useState();

    useEffect(() => {
        setDocumentTitle('News List');
        return () => loadTitle();
    }, []);

    const { isLoading, error, data } = useQuery({
        queryKey: ['getAdminNews'],
        queryFn: () => NewsApi.getAdminNews(context.authToken),
        enabled: context.authToken !== undefined && context.isAdmin,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data) {
            setRows(data.data);
            setTotalRowCount(data.data.length);
        }
    }, [data, error]);

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Titel',
            editable: false,
            flex: 1,
        },
        {
            field: 'creationDate',
            headerName: 'Erstelldatum',
            editable: false,
            flex: 1,
            valueFormatter: (params) =>
                params.value && parseDate(params?.value, 'DD.MM.YYYY'),
        },
        {
            field: 'editDate',
            headerName: 'Bearbeitungsdatum',
            editable: false,
            flex: 1,
            valueFormatter: (params) =>
                params.value && parseDate(params?.value, 'DD.MM.YYYY'),
        },
        {
            field: 'creator',
            headerName: 'Ersteller',
            editable: false,
            flex: 1,
        },
        {
            field: 'startDate',
            headerName: 'von',
            editable: false,
            flex: 1,
            valueFormatter: (params) =>
                params.value && parseDate(params?.value, 'DD.MM.YYYY'),
        },
        {
            field: 'endDate',
            headerName: 'bis',
            editable: false,
            flex: 1,
            valueFormatter: (params) =>
                params.value && parseDate(params?.value, 'DD.MM.YYYY'),
        },
        {
            field: 'visible',
            headerName: 'Sichtbar',
            editable: false,
            flex: 1,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={params.value ? 'Sichtbar' : 'Nicht Sichtbar'}>
                    <IconButton disableRipple color="primary">
                        <FontAwesomeIcon
                            icon={params.value ? faEye : faEyeSlash}
                        />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            field: 'Aktionen',
            headerName: '',
            flex: 1,
            hideable: false,
            sortable: false,
            renderHeader: () => (
                <Tooltip title="News Erstellen">
                    <IconButton
                        disableRipple
                        color="primary"
                        onClick={() => {
                            setNewsData(undefined);
                            setOpen(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            ),
            renderCell: (params) => (
                <Box>
                    <Tooltip title="News Bearbeiten">
                        <IconButton
                            disableRipple
                            color="info"
                            sx={{ height: 39 }}
                            onClick={() => {
                                setNewsData(params.row);
                                setOpen(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="News Löschen">
                        <IconButton
                            disableRipple
                            color="error"
                            sx={{ height: 39 }}
                            onClick={async () => {
                                await NewsApi.deleteNews(
                                    context.authToken,
                                    params.id,
                                );
                                queryClient.invalidateQueries('getAdminNews');
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Navbar title="News List" />
            <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    rowCount={totalRowCount}
                    autoHeight
                    experimentalFeatures={{ newEditingApi: true }}
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                />
            </Box>
            <NewsDialog
                open={open}
                onClose={() => setOpen(false)}
                newsData={newsData}
            />
        </Box>
    );
};

export default NewsList;
