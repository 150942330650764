import { FC, useContext, useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import LoginInput from '../../login/login-input';
import snackbarUtils from '../../../utils/snackbar/snackbar-utils';
import { useQuery, useQueryClient } from 'react-query';
import { Regions } from '../../../types/region';
import { AdminApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import { NonAdminRoles, Role, RoleMapping } from '../../../types/role';

export interface User {
    id: string;
    firstName?: string;
    lastName?: string;
    username: string;
    region?: string;
    role: Role;
    permittedUserIds?: string[];
    active: boolean;
    customerId: any;
}

interface CreateEditUserDialogProps {
    isEdit: boolean;
    existingUser?: User;
    open: boolean;
    onClose: () => void;
}

const CreateEditUserDialog: FC<CreateEditUserDialogProps> = (
    props: CreateEditUserDialogProps,
) => {
    const { open, onClose, isEdit, existingUser } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [customerId, setCustomerId] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [role, setRole] = useState<Role>(Role.USER);
    const [active, setActive] = useState(true);
    const [permittedUsers, setPermittedUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const context = useContext(AppContext);
    const [filteredUserList, setFilteredUserList] = useState<User[]>([]);
    const [userList, setUserList] = useState<User[]>([]);

    const {
        isLoading: dataIsLoading,
        error,
        data,
    } = useQuery({
        queryKey: ['findUsers'],
        queryFn: () => {
            const data: any = {
                listAll: true,
            };

            return AdminApi.getUsers(context.authToken, data);
        },
        enabled: context.authToken !== undefined && context.isAdmin && open,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data && data.data.users) {
            setUserList(data.data.users);
        }
    }, [data, error]);

    useEffect(() => {
        if (isEdit && existingUser) {
            setFirstName(existingUser?.firstName || '');
            setLastName(existingUser?.lastName || '');
            setUsername(existingUser.username);
            setRole(existingUser.role);
            setActive(existingUser.active);
            setRegion(existingUser.region || '');
            setCustomerId(existingUser.customerId || undefined);
        }
    }, [isEdit, existingUser]);

    useEffect(() => {
        if (isEdit && existingUser) {
            if (filteredUserList && existingUser.permittedUserIds) {
                setPermittedUsers(
                    filteredUserList.filter((u) =>
                        existingUser.permittedUserIds?.includes(u.id),
                    ),
                );
            }
        }
    }, [isEdit, existingUser, filteredUserList]);

    useEffect(() => {
        if ([Role.REGIONLEAD, Role.TEAMLEAD].includes(role)) {
            const nonAdminRoles = Object.keys(NonAdminRoles);
            setFilteredUserList(
                userList.filter((u) => {
                    if (
                        nonAdminRoles.includes(u.role) &&
                        u.id !== existingUser?.id
                    ) {
                        return true;
                    }
                    return false;
                }),
            );
        }
    }, [role, userList, existingUser]);

    const createUser = async () => {
        if (username && password) {
            setLoading(true);
            try {
                const d = await AdminApi.createUser(
                    context.authToken,
                    Object.assign(
                        {
                            firstName,
                            lastName,
                            username,
                            password,
                            role,
                            customerId: parseInt(customerId),
                            permittedUserIds: permittedUsers.map((u) => u.id),
                        },
                        role !== 'SUPERADMIN' && { region },
                    ),
                );
                setLoading(false);
                if (d) {
                    closeAndReload();
                }
            } catch (e) {
                setLoading(false);
            }
        }
    };

    const closeAndReload = () => {
        resetState();
        queryClient.invalidateQueries('getUsers');
        queryClient.invalidateQueries('findUsers');
        onClose();
    };

    const resetState = () => {
        setFirstName('');
        setLastName('');
        setUsername('');
        setPassword('');
        setRegion('');
        setCustomerId('');
        setPermittedUsers([]);
        setFilteredUserList([]);
        setRole(Role.USER);
    };

    const editUser = async () => {
        if (
            existingUser &&
            (username !== existingUser?.username ||
                region !== existingUser?.region ||
                role !== existingUser?.role ||
                password ||
                active !== existingUser?.active ||
                JSON.stringify(permittedUsers) !==
                    JSON.stringify(existingUser?.permittedUserIds))
        ) {
            if (password && password.length < 8) {
                snackbarUtils.warning(
                    'Das Passwort muss mindestens 8 Zeichen lang sein',
                );
                return;
            }
            setLoading(true);
            const update: any = {};
            if (existingUser.firstName !== firstName) {
                update.firstName = firstName;
            }
            if (existingUser.lastName !== lastName) {
                update.lastName = lastName;
            }
            if (existingUser.username !== username) {
                update.username = username;
            }
            if (password) {
                update.password = password;
            }
            if (region && existingUser.region !== region) {
                update.region = region;
            }
            if (existingUser.role !== role) {
                update.role = role;
            }
            if (existingUser.active !== active) {
                update.active = active;
            }
            if (existingUser.customerId !== customerId) {
                update.customerId = parseInt(customerId);
            }
            update.permittedUserIds = permittedUsers.map((u) => u.id);
            try {
                const d = await AdminApi.editUser(
                    context.authToken,
                    existingUser.id,
                    update,
                );
                setLoading(false);
                if (d) {
                    closeAndReload();
                }
            } catch (e) {
                setLoading(false);
            }
        } else {
            snackbarUtils.success('Keine Änderungen vorgenommen');
            resetState();
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                resetState();
                onClose();
            }}
        >
            <DialogTitle>
                {isEdit ? 'Benutzer bearbeiten' : 'Benutzer erstellen'}
            </DialogTitle>
            <DialogContent sx={{ width: '25rem' }}>
                <LoginInput
                    onChange={setFirstName.bind(this)}
                    label="Vorname"
                    initialValue={firstName}
                />
                <LoginInput
                    onChange={setLastName.bind(this)}
                    label="Nachname"
                    initialValue={lastName}
                />
                <LoginInput
                    onChange={setUsername.bind(this)}
                    label="Benutzername"
                    initialValue={username}
                    disabled={context.user?.userId === existingUser?.id}
                />
                <LoginInput
                    label="Passwort"
                    onChange={setPassword.bind(this)}
                    initialValue={password}
                    helperText={`Mindestens 8 Zeichen${
                        isEdit ? '. Überschreibt das aktuelle Passwort!' : ''
                    }`}
                    password
                />
                {role !== Role.SUPERADMIN && role !== Role.ADMIN && (
                    <Box display="flex" justifyContent="center">
                        <FormControl sx={{ width: '22rem', mt: 1 }}>
                            <InputLabel>Region</InputLabel>
                            <Select
                                value={region}
                                label="Region"
                                renderValue={(selected) => {
                                    if (Array.isArray(selected)) {
                                        return selected
                                            .map((s) => Regions[s])
                                            .join(', ');
                                    } else {
                                        return Regions[selected];
                                    }
                                }}
                                onChange={(e) => {
                                    setRegion(e.target.value);
                                }}
                            >
                                {Object.keys(Regions).map((s) => {
                                    return (
                                        <MenuItem key={s} value={s}>
                                            <ListItemText
                                                primary={Regions[s]}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <Box display="flex" justifyContent="center">
                    <FormControl sx={{ width: '22rem', mt: 1 }}>
                        <InputLabel>Rolle</InputLabel>
                        <Select
                            value={role}
                            label="Rolle"
                            onChange={(e) => {
                                setRole(Role[e.target.value]);
                            }}
                            disabled={context.user?.userId === existingUser?.id}
                        >
                            {context.isSuperadmin
                                ? Object.keys(Role).map((r) => {
                                      return (
                                          <MenuItem key={r} value={r}>
                                              <ListItemText
                                                  primary={RoleMapping[r]}
                                              />
                                          </MenuItem>
                                      );
                                  })
                                : Object.keys(NonAdminRoles).map((r) => {
                                      return (
                                          <MenuItem key={r} value={r}>
                                              <ListItemText
                                                  primary={RoleMapping[r]}
                                              />
                                          </MenuItem>
                                      );
                                  })}
                        </Select>
                    </FormControl>
                </Box>
                {(role === Role.REGIONLEAD || role === Role.TEAMLEAD) && (
                    <Box display="flex" justifyContent="center">
                        <FormControl sx={{ width: '22rem', mt: 1 }}>
                            <Autocomplete
                                disablePortal
                                options={filteredUserList}
                                value={permittedUsers}
                                getOptionLabel={(option: any) =>
                                    `${
                                        option.displayName || option.username
                                    } (${Regions[option.region]} ${
                                        RoleMapping[option.role]
                                    })`
                                }
                                multiple
                                onChange={(e, value) =>
                                    setPermittedUsers(value)
                                }
                                loading={dataIsLoading}
                                loadingText="Benutzer werden geladen"
                                sx={{ width: '22rem' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Zugewiesene Benutzer"
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                )}
                {context.isSuperadmin && (
                    <LoginInput
                        onChange={setCustomerId.bind(this)}
                        label="VP-Nummer "
                        initialValue={customerId}
                        disabled={!context.isSuperadmin}
                        helperText='Leeres Feld speichern um die "originale" VP-Nummer wiederherzustellen'
                    />
                )}
                {existingUser &&
                    existingUser.username !== context.user?.username && (
                        <Box display="flex" justifyContent="center">
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={active}
                                            onChange={() => setActive(!active)}
                                            disabled={
                                                existingUser.username ===
                                                context.user?.username
                                            }
                                        />
                                    }
                                    label="Benutzer aktiviert?"
                                />
                            </FormControl>
                        </Box>
                    )}
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{ mt: 2 }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        id="loginBtn"
                        onClick={() => {
                            if (isEdit) {
                                editUser();
                            } else {
                                createUser();
                            }
                        }}
                        className="formBtn"
                        disabled={
                            username === '' ||
                            (!isEdit &&
                                (password === '' || password.length < 8))
                        }
                        sx={{ color: 'white' }}
                    >
                        {loading ? (
                            <CircularProgress sx={{ color: 'white' }} />
                        ) : isEdit ? (
                            'Benutzer bearbeiten'
                        ) : (
                            'Benutzer erstellen'
                        )}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CreateEditUserDialog;
