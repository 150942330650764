import { faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface DiscardDialogProps {
    onClose: () => void;
    onDiscard: () => void;
    open: boolean;
}

const DiscardDialog: FC<DiscardDialogProps> = (props) => {
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Dialog open={open} onClose={props.onClose.bind(this)}>
            <DialogTitle>Änderungen verwerfen?</DialogTitle>
            <DialogContent>
                Wenn sie die Seite jetzt verlassen werden ihre Änderungen
                verworfen.
            </DialogContent>
            <DialogActions>
                <Tooltip title={'Verwerfen'}>
                    <IconButton
                        disableRipple
                        onClick={props.onDiscard.bind(this)}
                        color="error"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Schließen'}>
                    <IconButton
                        disableRipple
                        onClick={props.onClose.bind(this)}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </IconButton>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

export default DiscardDialog;
