import { Autocomplete, TextField } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { UserApi } from '../../api';
import { AppContext } from '../../hooks/context';
import { Role } from '../../types/role';

interface UserSelectionProps {
    userFilter: { username: string; id: string }[];
    setUserFilter: (userId: { username: string; id: string }[]) => void;
    includeInactive?: boolean;
}

const UserSelection: FC<UserSelectionProps> = (props) => {
    const { userFilter, setUserFilter, includeInactive } = props;
    const context = useContext(AppContext);

    const [users, setUsers] = useState<{ username: string; id: string }[]>([]);

    const { error, data } = useQuery({
        queryKey: ['getUsers', includeInactive],
        queryFn: () => UserApi.getPermittedUsers(context.authToken, includeInactive),
        enabled: context.authToken !== undefined && context.role !== Role.USER,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data) {
            setUsers(data.data);
        }
    }, [data, error]);

    return (
        <Autocomplete
            disablePortal
            value={userFilter}
            options={users}
            getOptionLabel={(option: any) =>
                `${option.displayName || option.username}${includeInactive && !option.active ? ' (Inaktiv)' : ''}`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple
            onChange={(e, value) => setUserFilter(value)}
            sx={{ width: '22rem' }}
            renderInput={(params) => <TextField {...params} label="Benutzer" />}
            disableCloseOnSelect={true}
        />
    );
};

export default UserSelection;
