import { FC, useContext, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { ContractApi } from '../../api';
import { AppContext } from '../../hooks/context';
import { useQueryClient } from 'react-query';

interface CreateEditUserDialogProps {
    open: boolean;
    contractToDelete: string | undefined;
    onClose: (deleted: boolean) => void;
}

const DeleteContractDialog: FC<CreateEditUserDialogProps> = (
    props: CreateEditUserDialogProps,
) => {
    const { open, onClose, contractToDelete } = props;
    const [loading, setLoading] = useState(false);
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    const deleteContract = async () => {
        if (contractToDelete) {
            setLoading(true);
            try {
                const r = await ContractApi.deleteContract(
                    context.authToken,
                    contractToDelete,
                );
                if (r) {
                    queryClient.invalidateQueries('getContracts');
                    onClose(true);
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose(false);
            }}
        >
            <DialogTitle>Vertrag löschen</DialogTitle>
            <DialogContent sx={{ width: '15rem' }}>
                {!loading ? (
                    <Box display="flex" justifyContent="space-between">
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => onClose(false)}
                            className="formBtn"
                        >
                            Abbrechen
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => deleteContract()}
                            className="formBtn"
                        >
                            Löschen
                        </Button>
                    </Box>
                ) : (
                    <Box sx={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default DeleteContractDialog;
