import {
    Box,
    IconButton,
    InputAdornment,
    SxProps,
    TextField,
    Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface LoginInputProps {
    label: string;
    onChange: Dispatch<SetStateAction<string>>;
    initialValue?: string;
    password?: boolean;
    email?: boolean;
    sx?: SxProps;
    showForgotPassword?: boolean;
    onPasswordOpen?: () => void;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

const LoginInput: FC<LoginInputProps> = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [inputValue, setInputValue] = useState(
        props.initialValue ? props.initialValue : '',
    );

    useEffect(() => {
        if (props.initialValue && props.initialValue !== inputValue) {
            setInputValue(props.initialValue);
        }
    }, [props.initialValue, inputValue]);

    return (
        <Box display="flex" justifyContent="center">
            {props.password ? (
                <Box flexDirection="column">
                    <TextField
                        error={props.error}
                        helperText={props.helperText}
                        color="secondary"
                        label={props.label}
                        disabled={props.disabled}
                        size="medium"
                        sx={{ mt: 2, width: '22rem' }}
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => {
                            props.onChange(e.currentTarget.value.trim());
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        id="showBtn"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                    >
                                        {showPassword ? (
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                            />
                                        ) : (
                                            <FontAwesomeIcon icon={faEye} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {props.showForgotPassword ? (
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontSize: '0.7rem',
                                mt: 1,
                                paddingLeft: '0.2rem',
                            }}
                            onClick={props.onPasswordOpen}
                            className="clickable"
                        >
                            Passwort vergessen?
                        </Typography>
                    ) : null}
                </Box>
            ) : (
                <TextField
                    error={props.error}
                    helperText={props.helperText}
                    disabled={props.disabled}
                    color="secondary"
                    label={props.label}
                    size="medium"
                    sx={{ mt: 2, width: '22rem' }}
                    type={props.email ? 'email' : 'text'}
                    value={inputValue}
                    onChange={(e) => {
                        props.onChange(e.currentTarget.value.trim());
                        setInputValue(e.currentTarget.value.trim());
                    }}
                />
            )}
        </Box>
    );
};

export default LoginInput;
