export enum Role {
    SUPERADMIN = 'SUPERADMIN',
    ADMIN = 'ADMIN',
    REGIONLEAD = 'REGIONLEAD',
    TEAMLEAD = 'TEAMLEAD',
    USER = 'USER',
}

export enum NonAdminRoles {
    REGIONLEAD = 'REGIONLEAD',
    TEAMLEAD = 'TEAMLEAD',
    USER = 'USER',
}

export const RoleMapping = {
    SUPERADMIN: 'Admin+',
    ADMIN: 'Admin',
    REGIONLEAD: 'Regionalleiter',
    TEAMLEAD: 'Teamleiter',
    USER: 'Promoter',
};
