import {
    faAddressBook,
    faPenToSquare,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AdminApi } from '../../../api';
import { Navbar, UserDataDialog } from '../../../components';
import CreateEditUserDialog, {
    User,
} from '../../../components/admin/create-edit-user-dialog/create-edit-user-dialog';
import UserFilterToolbar from '../../../components/datagrid/user-filter-toolbar';
import { AppContext } from '../../../hooks/context';
import { Regions } from '../../../types/region';
import { RoleMapping } from '../../../types/role';
import { loadTitle, setDocumentTitle } from '../../../utils/misc-utils';
import FocusPartnerUserFilterToolbar from '../../../components/datagrid/focuspartner-user-filter-toolbar';
import moment from 'moment';

interface DataGridElement {
    id: string;
    username: string;
    isAdmin: boolean;
}

const uploadedSortComparator = (a: string, b: string) => {
    if (a === '-') return -1;
    if (b === '-') return 1;
    const valueA = parseInt(a.split(' ')[0]);
    const valueB = parseInt(b.split(' ')[0]);
    if (valueA > valueB) {
        return 1;
    }
    if (valueA === valueB) {
        return 0;
    }
    return -1;
};

const FocusPartnerList = () => {
    const context = useContext(AppContext);
    const [createEditUserOpen, setCreateEditUserOpen] = useState(false);
    const [rows, setRows] = useState<DataGridElement[]>([]);
    const queryClient = useQueryClient();

    const [regionFilter, setRegionFilter] = useState('');
    const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment());

    const [userFilter, setUserFilter] = useState<
        { username: string; id: string }[]
    >([]);

    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const [sortModel, setSortModel] = useState<GridSortModel>([
        { field: 'username', sort: 'desc' },
    ]);

    useEffect(() => {
        setDocumentTitle('Fokuspartner Übersicht');
        return () => loadTitle();
    }, []);

    const { isLoading, error, data } = useQuery({
        queryKey: ['getUsers', regionFilter, selectedMonth, userFilter],
        queryFn: () => {
            const data: any = {
                selectedMonth,
            };

            if (regionFilter) {
                data.region = regionFilter;
            }

            if (userFilter.length > 0) {
                data.userIds = userFilter.map((u) => u.id);
            }

            return AdminApi.getFPUsers(context.authToken, data);
        },
        enabled: context.authToken !== undefined && context.isAdmin,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data) {
            setRows(data.data);
        }
    }, [data, error]);

    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Benutzer',
            editable: false,
            flex: 1,
            sortComparator: (a, b) => {
                if (a.toLowerCase() > b.toLowerCase()) {
                    return -1;
                }
                if (a.toLowerCase() === b.toLowerCase()) {
                    return 0;
                }
                return 1;
            },
            renderCell: (params) => {
                if (params.row.displayName) {
                    return `${params.row.displayName} (${params.row.username})`;
                }
                return params.row.username;
            },
        },
        {
            field: 'landline',
            headerName: 'Festnetz',
            editable: false,
            flex: 1,
            valueGetter: (params) =>
                !params.row.focusPartnerStatus[0] ? '-' : `${params.row.focusPartnerStatus[0].landlineUploaded} (Aktiviert: ${params.row.focusPartnerStatus[0].landlineActive})`,
            sortComparator: uploadedSortComparator,
        },
        {
            field: 'mobile',
            headerName: 'Mobilfunk',
            editable: false,
            flex: 1,
            valueGetter: (params) =>
                !params.row.focusPartnerStatus[0] ? '-' : `${params.row.focusPartnerStatus[0].mobileUploaded} (Aktiviert: ${params.row.focusPartnerStatus[0].mobileActive})`,
            sortComparator: uploadedSortComparator,
        },
        {
            field: 'congstar',
            headerName: 'Congstar',
            editable: false,
            flex: 1,
            valueGetter: (params) =>
                !params.row.focusPartnerStatus[0] ? '-' : `${params.row.focusPartnerStatus[0].congstarUploaded} (Aktiviert: ${params.row.focusPartnerStatus[0].congstarActive})`,
            sortComparator: uploadedSortComparator,
        },
        {
            field: 'availableSpins',
            headerName: 'Verfügb. Drehungen',
            editable: false,
            flex: 1,
            valueGetter: (params) => params.row.availableSpins,
        },
        {
            field: 'availablePoints',
            headerName: 'Verfügb. Punkte',
            editable: false,
            flex: 1,
            valueGetter: (params) => params.row.points,
        },
        {
            field: 'active',
            headerName: 'FP Aktiv',
            editable: false,
            flex: 1,
            valueGetter: (params) => !params.row.focusPartnerStatus[0] ? false : params.row.focusPartnerStatus[0].active,
            valueFormatter: (params) => (params?.value ? 'JA' : 'NEIN'),
        },
    ];

    return (
        <Box>
            <Navbar title="Fokuspartner Übersicht" />
            <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    components={{
                        Toolbar: FocusPartnerUserFilterToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            regionFilter,
                            setRegionFilter,
                            selectedMonth,
                            setSelectedMonth,
                            userFilter,
                            setUserFilter,
                        },
                    }}
                    // Pagination settings
                    // paginationMode="server"
                    page={pageNum}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pageSize={pageSize}
                    // End Pagination settings
                    // Sorting settings
                    // sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    sortingOrder={['desc', 'asc']}
                    // End sorting settings
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                    autoHeight
                />
            </Box>
        </Box>
    );
};

export default FocusPartnerList;
