import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UserApi } from '../../api';
import { AppContext } from '../../hooks/context';

interface UserStatusCardContentProps {
    monthSubtract?: number;
}

const UserStatusCardContent: FC<UserStatusCardContentProps> = (
    props: UserStatusCardContentProps,
) => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const { isLoading, error, data } = useQuery({
        queryKey: ['getUserStatus', props.monthSubtract],
        queryFn: () =>
            UserApi.getStatus(context.authToken, props.monthSubtract),
        enabled: context.authToken !== undefined,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [data, error]);

    const getStatusElement = (
        value: number,
        label: string,
        type: string,
        status: string = '',
    ) => {
        return (
            <Box
                flex="1"
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignSelf="center"
                sx={{ mt: { xs: 2, sm: 5 }, mb: { xs: 2, sm: 5 } }}
            >
                <Box
                    sx={{
                        border: 1,
                        borderRadius: '50%',
                        width: '5rem',
                        height: '5rem',
                        cursor: 'pointer',
                        mb: 1,
                    }}
                    display="flex"
                    justifyContent="center"
                    alignSelf="center"
                    alignItems="center"
                    onClick={() =>
                        navigate(`/contracts/${type}?status=${status}`)
                    }
                >
                    <Typography
                        sx={{ textAlign: 'center', fontWeight: 'bold' }}
                        variant="h5"
                        component="p"
                        color={
                            !status || status === 'ACTIVATED'
                                ? value > 0
                                    ? 'green'
                                    : 'red'
                                : value === 0
                                ? 'green'
                                : 'red'
                        }
                    >
                        {value}
                    </Typography>
                </Box>
                <Typography
                    sx={{ textAlign: 'center', mt: { xs: 0 } }}
                    variant="h6"
                    component="p"
                >
                    {label}
                </Typography>
            </Box>
        );
    };

    return (
        <Box alignContent="center">
            {isLoading || error ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="baseline"
                    sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                >
                    {getStatusElement(
                        data?.data.inquiryLandline,
                        'Rückfragen Festnetz',
                        'LANDLINE',
                        'INQUIRY',
                    )}
                    {getStatusElement(
                        data?.data.inquiryMobile,
                        'Rückfragen Mobilfunk',
                        'MOBILE',
                        'INQUIRY',
                    )}
                    {getStatusElement(
                        data?.data.inquiryCongstar,
                        'Rückfragen Congstar',
                        'CONGSTAR',
                        'INQUIRY',
                    )}
                    {getStatusElement(
                        data?.data.activeLandline,
                        'Aktiv Festnetz',
                        'LANDLINE',
                        'ACTIVATED',
                    )}
                    {getStatusElement(
                        data?.data.activeMobile,
                        'Aktiv Mobilfunk',
                        'MOBILE',
                        'ACTIVATED',
                    )}
                    {getStatusElement(
                        data?.data.activeCongstar,
                        'Aktiv Congstar',
                        'CONGSTAR',
                        'ACTIVATED',
                    )}
                    {getStatusElement(
                        data?.data.submittedAll,
                        'Eingereichte Verträge',
                        '',
                        '',
                    )}
                </Box>
            )}
        </Box>
    );
};

export default UserStatusCardContent;
