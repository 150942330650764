import { Box } from '@mui/material';
import { Navbar } from '../../components';
import { useEffect } from 'react';
import { loadTitle, setDocumentTitle } from '../../utils/misc-utils';

const BillingEmbed = () => {
    useEffect(() => {
        setDocumentTitle('Abrechnungen');
        return () => loadTitle();
    }, []);

    return (
        <Box sx={{ mb: 10 }}>
            <Navbar title="Abrechnungen" noMb={true} />
            <iframe
                src="https://my.con-corp.de/wbo/site/login"
                width="100%"
                height="auto"
                id=""
                title="Abrechnungen"
                className=""
                style={{ position: 'absolute', height: '100%', border: 'none' }}
            />
        </Box>
    );
};

export default BillingEmbed;
