import { Button, TextField, Theme } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import useClasses from './useClasses';
import { useQueryClient } from 'react-query';
import { useContext, useState } from 'react';
import { ContractApi } from '../../api';
import { AppContext } from '../../hooks/context';

const styles = (theme: Theme) => ({
    messageRow: {
        display: 'flex',
    },
    messageRowRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    messageBlue: {
        position: 'relative',
        marginLeft: '20px',
        marginBottom: '10px',
        padding: '10px',
        backgroundColor: '#A8DDFD',
        minWidth: '10vw',
        //height: "50px",
        textAlign: 'left',
        font: "400 .9em 'Open Sans', sans-serif",
        border: '1px solid #97C6E3',
        borderRadius: '10px',
        '&:after': {
            content: "''",
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '15px solid #A8DDFD',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            top: '0',
            left: '-15px',
        },
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '17px solid #97C6E3',
            borderLeft: '16px solid transparent',
            borderRight: '16px solid transparent',
            top: '-1px',
            left: '-17px',
        },
    },
    messageOrange: {
        position: 'relative',
        marginRight: '20px',
        marginBottom: '10px',
        padding: '10px',
        backgroundColor: '#f8e896',
        minWidth: '10vw',
        maxWidth: '60%',
        //height: "50px",
        textAlign: 'left',
        font: "400 .9em 'Open Sans', sans-serif",
        border: '1px solid #dfd087',
        borderRadius: '10px',
        '&:after': {
            content: "''",
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '15px solid #f8e896',
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            top: '0',
            right: '-15px',
        },
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: '17px solid #dfd087',
            borderLeft: '16px solid transparent',
            borderRight: '16px solid transparent',
            top: '-1px',
            right: '-17px',
        },
    },

    messageContent: {
        padding: 0,
        paddingBottom: 7,
        margin: 0,
    },
    messageTimeStampRight: {
        position: 'absolute',
        fontSize: '.85em',
        fontWeight: '300',
        marginTop: '10px',
        bottom: '0px',
        right: '5px',
    },

    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    displayName: {
        marginLeft: '20px',
    },

    wrapForm: {
        display: 'flex',
        justifyContent: 'center',
        width: '95%',
        margin: `0px auto`,
    },
    wrapText: {
        width: '100%',
    },
});

export const MessageLeft = (props: {
    message: string;
    timestamp: string;
    displayName: string;
}) => {
    const message = props.message ? props.message : 'Keine Nachricht';
    const timestamp = props.timestamp ? props.timestamp : '';
    const displayName = props.displayName ? props.displayName : '---';
    const classes = useClasses(styles);
    return (
        <>
            <div className={classes.messageRow}>
                <div>
                    <div className={classes.displayName}>{displayName}</div>
                    <div className={classes.messageBlue}>
                        <p className={classes.messageContent}>{message}</p>
                        <div className={classes.messageTimeStampRight}>
                            {timestamp}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const MessageRight = (props: { message: string; timestamp: string }) => {
    const classes = useClasses(styles);
    const message = props.message ? props.message : 'Keine Nachricht';
    const timestamp = props.timestamp ? props.timestamp : '';
    return (
        <div className={classes.messageRowRight}>
            <div className={classes.messageOrange}>
                <p className={classes.messageContent}>{message}</p>
                <div className={classes.messageTimeStampRight}>{timestamp}</div>
            </div>
        </div>
    );
};

export const TextInput = (props: {
    contractId: string | undefined;
    onSave: any;
}) => {
    const classes = useClasses(styles);
    const queryClient = useQueryClient();
    const [message, setMessage] = useState('');
    const context = useContext(AppContext);

    const sendMessage = async () => {
        try {
            if (message && props.contractId) {
                props.onSave();
                const r = await ContractApi.addMessage(
                    context.authToken,
                    props.contractId,
                    { message },
                );
                if (r && r.message) {
                    queryClient.invalidateQueries('getContract');
                    setMessage('');
                }
            }
        } catch (e) {}
    };

    return (
        <>
            <form className={classes.wrapForm} noValidate autoComplete="off">
                <TextField
                    id="standard-text"
                    label="Nachricht"
                    className={classes.wrapText}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            if (message.length > 0) {
                                sendMessage();
                            }
                        }
                    }}
                    //margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={sendMessage}
                >
                    <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
            </form>
        </>
    );
};
