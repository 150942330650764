import { faLock, faUnlock, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Avatar,
    Stack,
    Typography,
    TextField,
    Button,
    IconButton,
    CircularProgress,
    Tooltip,
    FormControl,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useContext, useState, useEffect, FC } from 'react';
import { UserApi } from '../../api';
import { AppContext } from '../../hooks/context';
import { useSavePrompt } from '../../hooks/useBlocker';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';
import { UserFileType } from '../../types/user-file-types';
import { UserFileData } from '../../types';
import UserUploadButton from '../user-upload/user-upload-button';
import { useQueryClient } from 'react-query';

const UploadButtonsConst = [
    {
        name: 'Gewerbeanmeldung',
        type: UserFileType.BUSINESS_REGISTRATION,
    },
    {
        name: 'Bestätigung Steuernummer',
        type: UserFileType.TAX_NUMBER_CONFIRMATION,
    },
    {
        name: 'Bestätigung SteuerID',
        type: UserFileType.TAX_ID_CONFIRMATION,
    },
    {
        name: 'Personalausweis',
        type: UserFileType.IDENTITY_CARD,
    },
    {
        name: 'Führerschein',
        type: UserFileType.DRIVERS_LICENSE,
    },
    {
        name: 'Sonstige',
        type: UserFileType.OTHER,
    },
];

export interface UserDataType {
    firstName: string;
    lastName: string;
    address: string;
    birthday: string;
    taxNumber: string;
    taxId: string;
    lockedFields: string[];
    lockedFileUploads: UserFileType[];
    isComplete: boolean;
}

interface UserDataProps {
    userData: Partial<UserDataType>;
    fileData: { [key: string]: UserFileData[] };
    admin?: boolean;
    id: string;
    onBlock?: (block: boolean) => void;
}

const UserData: FC<UserDataProps> = (props) => {
    const { userData, fileData, onBlock } = props;
    const context = useContext(AppContext);
    const [uploadFiles, setUploadFiles] = useState<{
        [key: string]: UserFileData[];
    }>({});
    const [uploadButtons, setUploadButtons] = useState(UploadButtonsConst);
    const [address, setAddress] = useState('');
    const [birthday, setBirthday] = useState<string | null>(null);
    const [taxNumber, setTaxNumber] = useState('');
    const [taxId, setTaxId] = useState('');
    const [sendDisabled, setSendDisabled] = useState(true);
    const [deleteFileIds, setDeleteFileIds] = useState<string[]>([]);
    const [blockRoute, setBlockRoute] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState<any>({});
    const [isComplete, setIsComplete] = useState<boolean>(true);

    const queryClient = useQueryClient();

    useSavePrompt(blockRoute);

    useEffect(() => {
        let blocked = Object.keys(changes).length > 0;
        if (!blocked && deleteFileIds.length > 0) blocked = true;
        if (props.admin) {
            setSendDisabled(!blocked);
        }
        onBlock && onBlock(blocked);
    }, [deleteFileIds, changes, onBlock, props.admin]);

    useEffect(() => {
        if (userData) {
            setAddress(userData.address || '');
            setBirthday(userData.birthday || null);
            setTaxId(userData.taxId || '');
            setTaxNumber(userData.taxNumber || '');
            setIsComplete(userData.isComplete || false);
        }
        setUploadFiles(fileData);
    }, [userData, fileData]);

    useEffect(() => {
        return () => {
            setBlockRoute(false);
            setBirthday('');
            setTaxId('');
            setTaxNumber('');
            setChanges({});
            setUploadFiles({});
            setIsComplete(false);
        };
    }, []);

    useEffect(() => {
        setSendDisabled(Object.keys(changes).length === 0);
    }, [changes]);

    useEffect(() => {
        let disabled = true;
        Object.keys(uploadFiles).forEach((f) => {
            uploadFiles[f].forEach((f2) => {
                if (f2.file) disabled = false;
            });
        });
        setSendDisabled(disabled);
    }, [uploadFiles, fileData]);

    const submitUserData = async () => {
        const formData = new FormData();

        for (const key in uploadFiles) {
            uploadFiles[key].forEach((fileData) => {
                if (fileData.file) {
                    formData.append(key, fileData.file);
                }
            });
        }

        for (const k in changes) {
            if (!userData.lockedFields?.includes(k)) {
                formData.append(k, changes[k]);
            }
        }

        if (props.admin) {
            formData.append('isComplete', isComplete.toString());
        }

        if (deleteFileIds.length > 0) {
            formData.append('deleteFileIdString', deleteFileIds.toString());
        }

        try {
            setLoading(true);
            await UserApi.updateUserData(context.authToken, props.id, formData);
            setBlockRoute(false);
            setLoading(false);
            setChanges({});
            setDeleteFileIds([]);
            queryClient.invalidateQueries('getUserData');
        } catch (error) {
            console.error(error);
        }
    };

    const onUploadFile = (file: File, type: UserFileType) => {
        if (file.size > 10485760) {
            snackbarUtils.error('Datei ist zu groß. Nicht größer als 10MB');
            return;
        }
        setUploadFiles((files) => {
            const fileData = {
                file,
                url: URL.createObjectURL(file),
                name: file.name,
                id: Date.now() + file.name,
                new: true,
            };
            if (type === UserFileType.PROFILE_PICTURE) {
                if (files[type]) {
                    onRemoveFile(UserFileType.PROFILE_PICTURE, files[type][0]);
                }
                files[type] = [fileData];
            } else if (!files[type]) {
                files[type] = [fileData];
            } else {
                files[type].push(fileData);
            }

            return files;
        });
        setSendDisabled(false);

        // this is needed to update state after adding a File to uploadFiles array
        setUploadButtons([...uploadButtons]);
    };

    useEffect(() => {
        if (isComplete !== userData.isComplete) {
            setSendDisabled(false);
        }
    }, [isComplete]);

    const onRemoveFile = (type: UserFileType, fileData: UserFileData) => {
        if (!fileData.new) {
            setDeleteFileIds((ids) => [...ids, fileData.id ? fileData.id : '']);
        }
        setUploadFiles((files) => {
            files[type] = files[type].filter((file) => file.id !== fileData.id);

            return files;
        });

        setUploadButtons([...uploadButtons]);
    };

    const unlockUserData = async (
        field?: string,
        fileUpload?: UserFileType,
    ) => {
        try {
            await UserApi.unlockUserDataFields(context.authToken, props.id, {
                field,
                fileUpload,
            });
            queryClient.invalidateQueries('getUserData');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box>
            {(!props.admin || fileData[UserFileType.PROFILE_PICTURE]) && (
                <Box display="flex" justifyContent="center" mb={2}>
                    <input
                        accept="image/*"
                        hidden
                        id="avatar-image-upload"
                        type="file"
                        onChange={(e) =>
                            e.target &&
                            e.target.files &&
                            onUploadFile(
                                e.target?.files?.[0],
                                UserFileType.PROFILE_PICTURE,
                            )
                        }
                    />
                    <label htmlFor="avatar-image-upload">
                        <Avatar
                            src={
                                fileData[UserFileType.PROFILE_PICTURE]
                                    ? fileData[UserFileType.PROFILE_PICTURE][0]
                                          .url
                                    : ''
                            }
                            sx={{ width: 150, height: 150 }}
                        >
                            <FontAwesomeIcon icon={faUpload} />
                        </Avatar>
                    </label>
                </Box>
            )}
            <Stack
                direction={{ md: 'column', lg: 'row' }}
                spacing={4}
                display="flex"
                justifyContent="center"
                alignItems={{ xs: 'center', md: 'center', lg: 'start' }}
            >
                <Box>
                    <Typography gutterBottom variant="h5" mb={2}>
                        Persönliche Daten
                    </Typography>
                    <Stack direction="column" spacing={2} width="22rem">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Geburtsdatum"
                                disabled={userData.lockedFields?.includes(
                                    'birthday',
                                )}
                                onChange={(v) => {
                                    setBirthday(v);
                                    setChanges({ ...changes, birthday: v });
                                }}
                                inputFormat="DD.MM.YYYY"
                                value={birthday}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                            {props.admin && (
                                <Tooltip
                                    title={
                                        userData.lockedFields?.includes(
                                            'birthday',
                                        )
                                            ? 'Feld entsperren'
                                            : 'Feld sperren'
                                    }
                                >
                                    <IconButton
                                        onClick={() =>
                                            unlockUserData('birthday')
                                        }
                                        size="small"
                                        disableRipple
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                userData.lockedFields?.includes(
                                                    'birthday',
                                                )
                                                    ? faUnlock
                                                    : faLock
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </LocalizationProvider>
                        <TextField
                            onChange={(e) => {
                                setAddress(e.currentTarget.value);
                                setChanges({
                                    ...changes,
                                    address: e.currentTarget.value,
                                });
                            }}
                            disabled={userData.lockedFields?.includes(
                                'address',
                            )}
                            value={address}
                            label="Anschrift"
                            InputProps={{
                                endAdornment: props.admin && (
                                    <Tooltip
                                        title={
                                            userData.lockedFields?.includes(
                                                'address',
                                            )
                                                ? 'Feld entsperren'
                                                : 'Feld sperren'
                                        }
                                    >
                                        <IconButton
                                            onClick={() =>
                                                unlockUserData('address')
                                            }
                                            size="small"
                                            disableRipple
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    userData.lockedFields?.includes(
                                                        'address',
                                                    )
                                                        ? faUnlock
                                                        : faLock
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                        />
                        <TextField
                            onChange={(e) => {
                                setTaxNumber(e.currentTarget.value);
                                setChanges({
                                    ...changes,
                                    taxNumber: e.currentTarget.value,
                                });
                            }}
                            disabled={userData.lockedFields?.includes(
                                'taxNumber',
                            )}
                            value={taxNumber}
                            label="Steuernummer"
                            InputProps={{
                                endAdornment: props.admin && (
                                    <Tooltip
                                        title={
                                            userData.lockedFields?.includes(
                                                'taxNumber',
                                            )
                                                ? 'Feld entsperren'
                                                : 'Feld sperren'
                                        }
                                    >
                                        <IconButton
                                            onClick={() =>
                                                unlockUserData('taxNumber')
                                            }
                                            size="small"
                                            disableRipple
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    userData.lockedFields?.includes(
                                                        'taxNumber',
                                                    )
                                                        ? faUnlock
                                                        : faLock
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                        />
                        <TextField
                            onChange={(e) => {
                                setTaxId(e.currentTarget.value);
                                setChanges({
                                    ...changes,
                                    taxId: e.currentTarget.value,
                                });
                            }}
                            value={taxId}
                            disabled={userData.lockedFields?.includes('taxId')}
                            InputProps={{
                                endAdornment: props.admin && (
                                    <Tooltip
                                        title={
                                            userData.lockedFields?.includes(
                                                'taxId',
                                            )
                                                ? 'Feld entsperren'
                                                : 'Feld sperren'
                                        }
                                    >
                                        <IconButton
                                            onClick={() =>
                                                unlockUserData('taxId')
                                            }
                                            size="small"
                                            disableRipple
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    userData.lockedFields?.includes(
                                                        'taxId',
                                                    )
                                                        ? faUnlock
                                                        : faLock
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                ),
                            }}
                            label="SteuerID"
                        />
                    </Stack>
                </Box>
                <Box>
                    <Typography gutterBottom variant="h5" mb={2}>
                        Dateien
                    </Typography>
                    <Stack direction="column" spacing={2} width="18rem">
                        {uploadButtons.map((button) => (
                            <UserUploadButton
                                disabled={userData.lockedFileUploads?.includes(
                                    button.type,
                                )}
                                admin={props.admin}
                                key={button.type}
                                title={button.name}
                                onRemove={(fileData) =>
                                    onRemoveFile(button.type, fileData)
                                }
                                onUpload={(file: File) =>
                                    onUploadFile(file, button.type)
                                }
                                unlockUserData={unlockUserData}
                                fileData={uploadFiles[button.type]}
                                type={button.type}
                            />
                        ))}
                    </Stack>
                </Box>
            </Stack>
            {props.admin && (
                <Box display="flex" justifyContent="center">
                    <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isComplete}
                                    onChange={() => setIsComplete(!isComplete)}
                                />
                            }
                            label="Daten vollständig?"
                        />
                    </FormControl>
                </Box>
            )}
            {loading ? (
                <CircularProgress />
            ) : (
                <Button
                    color="success"
                    variant="contained"
                    component="label"
                    disabled={sendDisabled}
                    sx={{ mt: 2 }}
                    onClick={() => submitUserData()}
                >
                    Daten einreichen
                </Button>
            )}
            <Typography variant="inherit" sx={{ mt: 2 }}>
                Eingereichte Daten werden gesperrt und können nur mehr durch
                einen Administrator freigegeben werden
            </Typography>
        </Box>
    );
};

export default UserData;
