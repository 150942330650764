import { axiosInstance } from '../../utils/api/api-utils';

export default class NewsApi {
    static getNews(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/news', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getAdminNews(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/news/admin', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static createNews(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/news/', body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static editNews(
        token: string | undefined,
        id: string,
        body: any,
    ): Promise<any> {
        return axiosInstance
            .post(`/api/news/${id}`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static deleteNews(token: string | undefined, id: any): Promise<any> {
        return axiosInstance
            .delete(`/api/news/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    }
}
