import { FC, useContext, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const AuthenticatedRoute: FC = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!context.isAuthenticated && !context.authToken) {
            navigate('/login');
        }
    }, [context.isAuthenticated, context.authToken, navigate]);

    return context.user || context.isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate to="/login" />
    );
};

export default AuthenticatedRoute;
