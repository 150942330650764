import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    Button,
    TextField,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { ContractApi } from '../../../api';
import { AppContext } from '../../../hooks/context';
import moment from 'moment-timezone';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

interface ExportDialogProps {
    open: boolean;
    onClose: () => void;
}

const ExportDialog: FC<ExportDialogProps> = (props) => {
    const context = useContext(AppContext);
    const [date, setDate] = useState<moment.Moment>(moment());
    const [loading, setLoading] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const startExport = async () => {
        setLoading(true);
        try {
            await ContractApi.startExport(context.authToken, {
                fromDate: moment(date).startOf('month').toISOString(),
                toDate: moment(date).endOf('month').toISOString(),
            });
            setLoading(false);
            queryClient.invalidateQueries('getExports');
            props.onClose();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={props.open}
            onClose={props.onClose}
        >
            <DialogContent sx={{ textAlign: 'center' }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{ mt: 2 }}
                >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box sx={{ mb: 5 }}>
                            <DatePicker
                                label="Monat auswählen"
                                onChange={(v) => setDate(moment(v))}
                                views={['year', 'month']}
                                maxDate={moment()}
                                inputFormat="MM.YYYY"
                                value={date}
                                renderInput={(params) => (
                                    <TextField fullWidth {...params} />
                                )}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            startExport();
                        }}
                        className="formBtn"
                        sx={{ color: 'white' }}
                        startIcon={
                            loading ? null : (
                                <FontAwesomeIcon icon={faFileExcel} />
                            )
                        }
                    >
                        {loading ? (
                            <CircularProgress sx={{ color: 'white' }} />
                        ) : (
                            'Export erstellen'
                        )}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ExportDialog;
