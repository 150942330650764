import {
    faCircleXmark,
    faFloppyDisk,
    faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FC, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import NewsApi from '../../api/news/news';
import { AppContext } from '../../hooks/context';

interface NewsDialogProps {
    open: boolean;
    onClose: () => void;
    newsData?: NewsData;
}

interface NewsData {
    id: string;
    startDate?: Date;
    endDate?: Date;
    title: string;
    text: string;
    imageName?: string;
    imageUrl?: string;
    visible?: boolean;
}

const NewsDialog: FC<NewsDialogProps> = (props) => {
    const context = useContext(AppContext);
    const queryClient = useQueryClient();

    const [open, setOpen] = useState(props.open);
    const [text, setText] = useState('');
    const [textError, setTextError] = useState(false);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [visible, setVisible] = useState(false);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [image, setImage] = useState<File>();
    const [imageName, setImageName] = useState<string>();
    const [imageUrl, setImageUrl] = useState<string>();
    const [deleteImage, setDeleteImage] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        setDeleteImage(false);
        setTextError(false);
        setTitleError(false);
        if (props.newsData) {
            setText(props.newsData?.text);
            setTitle(props.newsData.title);
            setImageName(props.newsData.imageName);
            setImageUrl(props.newsData.imageUrl);
            setStartDate(
                props.newsData.startDate ? props.newsData.startDate : null,
            );
            setEndDate(props.newsData.endDate ? props.newsData.endDate : null);
            setVisible(props.newsData.visible ? props.newsData.visible : false);
        } else {
            setText('');
            setTitle('');
            setImageName(undefined);
            setImageUrl(undefined);
            setStartDate(null);
            setEndDate(null);
            setVisible(false);
        }
    }, [props.newsData]);

    const onSave = async () => {
        let errors = false;
        if (title === '') {
            setTitleError(true);
            errors = true;
        }
        if (text === '') {
            setTextError(true);
            errors = true;
        }

        if (!errors) {
            const newsBody = Object.assign(
                {
                    title,
                    text,
                },
                image && { image },
                startDate && { startDate },
                endDate && { endDate },
                visible && { visible },
                deleteImage && { deleteImage },
            );
            try {
                if (props.newsData) {
                    await NewsApi.editNews(
                        context.authToken,
                        props.newsData.id,
                        newsBody,
                    );
                } else {
                    await NewsApi.createNews(context.authToken, newsBody);
                }

                queryClient.invalidateQueries('getAdminNews');
                props.onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onSelectImage = (image: File) => {
        if (props.newsData?.imageUrl) {
            setDeleteImage(true);
        }
        setImageName(image.name);
        setImageUrl(URL.createObjectURL(image));
        setImage(image);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>News Erstellen</DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minWidth: '22rem',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Box
                        maxHeight={250}
                        maxWidth={300}
                        component="img"
                        alignSelf="center"
                        src={imageUrl}
                        m={2}
                    />
                    <Button variant="contained" component="label" fullWidth>
                        Bild Hochladen
                        <input
                            type="file"
                            hidden
                            accept="image/png, image/gif, image/jpeg"
                            onClick={(e) => (e.currentTarget.value = '')}
                            onChange={(e) => {
                                e.target &&
                                    e.target.files &&
                                    onSelectImage(e.target.files[0]);
                            }}
                        />
                    </Button>
                    <Box
                        mt={1}
                        display={imageName ? 'flex' : 'none'}
                        justifyContent="space-evenly"
                        alignItems="center"
                    >
                        <a
                            href={imageUrl}
                            target="_blank"
                            download
                            rel="noreferrer"
                        >
                            {imageName}
                        </a>

                        <IconButton
                            onClick={() => {
                                if (props.newsData?.imageUrl) {
                                    setDeleteImage(true);
                                }
                                setImage(undefined);
                                setImageName(undefined);
                                setImageUrl(undefined);
                            }}
                            size="small"
                            color="error"
                            disableRipple
                        >
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </IconButton>
                    </Box>
                    <TextField
                        value={title}
                        onChange={(e) => {
                            setTitleError(false);
                            setTitle(e.target.value);
                        }}
                        error={titleError}
                        color="secondary"
                        label="Titel"
                        size="medium"
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        value={text}
                        onChange={(e) => {
                            setTextError(false);
                            setText(e.target.value);
                        }}
                        error={textError}
                        color="secondary"
                        label="Text"
                        multiline
                        rows={3}
                        sx={{ mt: 2 }}
                    />
                    <DatePicker
                        label="Von"
                        onChange={(v) => setStartDate(v)}
                        inputFormat="DD.MM.YYYY"
                        value={startDate}
                        renderInput={(params) => (
                            <TextField sx={{ mt: 2 }} fullWidth {...params} />
                        )}
                    />
                    <DatePicker
                        label="Bis"
                        onChange={(v) => setEndDate(v)}
                        inputFormat="DD.MM.YYYY"
                        value={endDate}
                        renderInput={(params) => (
                            <TextField sx={{ mt: 2 }} fullWidth {...params} />
                        )}
                    />
                    <FormControlLabel
                        sx={{ width: '13rem', mt: 2 }}
                        control={
                            <Checkbox
                                value={visible}
                                checked={visible}
                                onChange={() => setVisible(!visible)}
                            />
                        }
                        label="Sichtbar?"
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Tooltip title="Speichern">
                    <IconButton disableRipple color="primary" onClick={onSave}>
                        <FontAwesomeIcon icon={faFloppyDisk} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Schließen">
                    <IconButton disableRipple onClick={props.onClose}>
                        <FontAwesomeIcon icon={faX} />
                    </IconButton>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};

export default NewsDialog;
