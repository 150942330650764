import { Buffer } from 'buffer';

export const handleFileUpload = (file: File, reader: FileReader) => {
    reader.readAsText(file); // fires onload when done.
};

export const handleFileRead = (event: ProgressEvent<FileReader>) => {
    if (event && event.target && event.target.result) {
        const base64EncodedDocument = Buffer.from(
            event.target.result.toString(),
        ).toString('base64');
        window.localStorage.setItem('contract', base64EncodedDocument);
    }
};

export const makeid = (length: number) => {
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        );
    }
    return result;
};
