import moment from 'moment-timezone';

export const convertISOStringToDate = (dateString: string): Date => {
    const returnDate = moment.utc(dateString);
    return moment(returnDate).local().toDate();
};

export const convertDateToFormattedString = (
    date: Date | string,
    hourSubtract: number = 0,
    hourAdd: number = 0,
    overrideFormat?: string,
) => {
    let d = moment(date).local();
    if (hourSubtract > 0) d = d.subtract(hourSubtract, 'hours');
    if (hourAdd > 0) d = d.add(hourSubtract, 'hours');
    return d
        .format(overrideFormat ? overrideFormat : 'HH:mm | DD.MM.YYYY')
        .toString();
};
