import { createTheme } from '@mui/material';
import moment from 'moment';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { titleSuffix } from './constants';
import { deDE } from '@mui/material/locale';
import { deDE as deDEGrid } from '@mui/x-data-grid';
import { deDE as deDEDate } from '@mui/x-date-pickers';

export const setDocumentTitle = (title: string) => {
    if (!title) {
        document.title = titleSuffix;
        return;
    }
    document.title = `${title} | ${titleSuffix}`;
};

export const loadTitle = () => {
    document.title = `... | ${titleSuffix}`;
};

export const useURLQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#069FEA',
            },
            secondary: {
                main: '#BAB06E',
            },
        },
        typography: {
            fontFamily: 'Roboto',
        },
    },
    deDE,
    deDEGrid,
    deDEDate,
);

export const parseDate = (date: Date, format: string = 'DD.MM') => {
    return moment(date).format(format);
};
