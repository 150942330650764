import { axiosInstance } from '../../utils/api/api-utils';

export default class PointsApi {
    static getSegments(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/points/segments', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getAvailableSpins(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/points/availableSpins', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getPoints(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/points/points', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static spinWheel(token: string | undefined, spinId: string): Promise<any> {
        return axiosInstance
            .get('/api/points/spin/' + spinId, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static testSpinWheel(
        token: string | undefined,
        spinId: string,
    ): Promise<any> {
        return axiosInstance
            .get('/api/points/test-spin/' + spinId, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }
}
