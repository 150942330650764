import { Box } from '@mui/system';
import { FC, useContext, useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { Regions } from '../../types/region';
import { AppContext } from '../../hooks/context';
import { Role } from '../../types/role';
import { RoleMapping } from '../../types/role';

interface FilterToolbarProps {
    searchText: string;
    setSearchText: (v: string) => void;
    regionFilter: string;
    setRegionFilter: (v: string) => void;
    showInactiveUsers: boolean;
    setShowInactiveUsers: (v: boolean) => void;
    roleFilter: string;
    setRoleFilter: (v: string) => void;
}

const UserFilterToolbar: FC<FilterToolbarProps> = (
    props: FilterToolbarProps,
) => {
    const {
        searchText,
        setSearchText,
        regionFilter,
        setRegionFilter,
        showInactiveUsers,
        setShowInactiveUsers,
        roleFilter,
        setRoleFilter,
    } = props;
    const context = useContext(AppContext);
    const [transientSearchText, setTransientSearchText] = useState(searchText);

    const [regionFilterOptions, setRegionFilterOptions] = useState<
        JSX.Element[]
    >([]);
    const [roleFilterOptions, setRoleFilterOptions] = useState<JSX.Element[]>(
        [],
    );

    useEffect(() => {
        if (context.isAdmin) {
            const options: JSX.Element[] = [];
            options.push(
                <MenuItem key="DefaultRegion" value="">
                    <ListItemText primary="Region wählen" />
                </MenuItem>,
            );
            let r: string[] = Object.keys(Regions);
            r.forEach((s) => {
                options.push(
                    <MenuItem key={s} value={s}>
                        <ListItemText primary={Regions[s]} />
                    </MenuItem>,
                );
            });
            setRegionFilterOptions(options);
        }
    }, [context.isAdmin]);

    useEffect(() => {
        if (context.isAdmin) {
            const options: JSX.Element[] = [];
            options.push(
                <MenuItem key="DefaultRole" value="">
                    <ListItemText primary="Rolle wählen" />
                </MenuItem>,
            );
            let r: string[] = [];
            if (context.isSuperadmin) {
                r = Object.keys(Role);
            } else {
                r = [Role.REGIONLEAD, Role.TEAMLEAD, Role.USER];
            }
            r.forEach((s) => {
                options.push(
                    <MenuItem key={s} value={s}>
                        <ListItemText primary={RoleMapping[s]} />
                    </MenuItem>,
                );
            });
            setRoleFilterOptions(options);
        }
    }, [context]);

    return (
        <Box>
            <Box
                display="flex"
                sx={{
                    p: 1,
                    width: '50rem',
                    flexDirection: { xs: 'column', sm: 'row' },
                }}
            >
                <Box
                    sx={{
                        width: '22rem',
                        flex: 1,
                        mr: { sm: 5 },
                        mt: { xs: 2 },
                    }}
                >
                    <FormControl sx={{ width: '22rem' }}>
                        <InputLabel>Region</InputLabel>
                        <Select
                            value={regionFilter}
                            label="Region"
                            onChange={(e) => setRegionFilter(e.target.value)}
                        >
                            {regionFilterOptions}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        width: '22rem',
                        flex: 1,
                        mr: { sm: 5 },
                        mt: { xs: 2 },
                    }}
                >
                    <FormControl sx={{ width: '22rem' }}>
                        <InputLabel>Rolle</InputLabel>
                        <Select
                            value={roleFilter}
                            label="Rolle"
                            onChange={(e) => setRoleFilter(e.target.value)}
                        >
                            {roleFilterOptions}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        width: '22rem',
                        flex: 1,
                        mr: { sm: 5 },
                        mt: { xs: 2 },
                    }}
                >
                    <TextField
                        fullWidth
                        label="Suche"
                        sx={{ width: '22rem' }}
                        value={transientSearchText}
                        onChange={(e) => {
                            const value = e.currentTarget.value;
                            setTransientSearchText(value);
                            if (value === '') setSearchText(value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSearchText(transientSearchText);
                            }
                        }}
                        InputProps={{
                            endAdornment:
                                transientSearchText.length > 0 ? (
                                    <InputAdornment position="end">
                                        <Tooltip title="Suche verwerfen">
                                            <IconButton
                                                onClick={() => {
                                                    setTransientSearchText('');
                                                    setSearchText('');
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faX} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Suchen">
                                            <IconButton
                                                onClick={() =>
                                                    setSearchText(
                                                        transientSearchText,
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ) : null,
                        }}
                    />
                </Box>
                <Box sx={{ width: '22rem', flex: 1, pt: 3 }}>
                    <FormControlLabel
                        sx={{ width: '20rem' }}
                        control={
                            <Checkbox
                                value={showInactiveUsers}
                                checked={showInactiveUsers}
                                onChange={(e) =>
                                    setShowInactiveUsers(e.target.checked)
                                }
                            />
                        }
                        label="Inaktive Benutzer anzeigen"
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default UserFilterToolbar;
