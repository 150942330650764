import { Box, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { UserApi } from '../../api';
import { AppContext } from '../../hooks/context';
import { Regions } from '../../types/region';

interface PermittedUsersStatusTableProps {
    monthSubtract?: number;
    region?: string;
}

const PermittedUsersStatusTable: FC<PermittedUsersStatusTableProps> = (
    props: PermittedUsersStatusTableProps,
) => {
    const context = useContext(AppContext);
    const { isLoading, error, data } = useQuery({
        queryKey: [
            'getPermittedUsersStatus',
            props.monthSubtract,
            props.region,
        ],
        queryFn: () =>
            UserApi.getPermittedUsersStatus(
                context.authToken,
                props.monthSubtract,
                props.region,
            ),
        enabled: context.authToken !== undefined,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        }
    }, [data, error]);

    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Benutzername',
            editable: false,
            flex: 1,
        },
        {
            field: 'active',
            headerName: 'Aktive Verträge',
            editable: false,
            flex: 1,
        },
        {
            field: 'inquiry',
            headerName: 'Rückfragen',
            editable: false,
            flex: 1,
        },
        {
            field: 'submittedAll',
            headerName: 'Eingereichte Verträge',
            editable: false,
            flex: 1,
        },
    ];

    return (
        <Box alignContent="center">
            {isLoading || error ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="baseline"
                    sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                >
                    <DataGrid
                        columns={columns}
                        rows={data.data}
                        loading={isLoading}
                        getRowId={(row) => row.id}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        autoHeight
                        disableColumnSelector
                        disableColumnFilter
                        disableColumnMenu
                        initialState={{
                            sorting: {
                                sortModel: [
                                    { field: 'submittedAll', sort: 'desc' },
                                ],
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default PermittedUsersStatusTable;
