import { Paper, Box, Chip, Typography } from '@mui/material';
import { FC } from 'react';
import { parseDate } from '../../utils/misc-utils';

interface NewsProps {
    startDate?: Date;
    endDate?: Date;
    text: string;
    title: string;
    imageUrl?: string;
}

const News: FC<NewsProps> = (props) => {
    return (
        <Paper sx={{ m: 2, pt: 1, pl: 1, pb: 1 }}>
            <Box
                sx={{
                    maxHeight: 350,
                    display: 'flex',
                    alignContent: 'center',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'flex-start',
                    pt: 1,
                    pb: 1,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ width: 400 }}
                >
                    <span style={{ width: 400 }}>
                        {props.imageUrl && (
                            <Box
                                maxHeight={
                                    props.endDate || props.startDate ? 200 : 250
                                }
                                maxWidth={400}
                                component="img"
                                src={props.imageUrl}
                            />
                        )}
                    </span>
                </Box>
                <Box
                    sx={{ overflowY: 'auto', mr: 0, ml: 1 }}
                    textAlign="justify"
                >
                    {props.endDate && props.startDate && (
                        <Box sx={{ mt: 1, mb: 1 }} alignSelf="baseline">
                            <Chip
                                variant="outlined"
                                label={
                                    parseDate(props.startDate) +
                                    ' - ' +
                                    parseDate(props.endDate)
                                }
                                sx={{ fontWeight: 800 }}
                            />
                        </Box>
                    )}
                    {props.endDate && !props.startDate && (
                        <Box sx={{ mt: 1, mb: 1 }} alignSelf="baseline">
                            <Chip
                                variant="outlined"
                                label={'bis ' + parseDate(props.endDate)}
                                sx={{ fontWeight: 800 }}
                            />
                        </Box>
                    )}
                    {!props.endDate && props.startDate && (
                        <Box sx={{ mt: 1, mb: 1 }} alignSelf="baseline">
                            <Chip
                                variant="outlined"
                                label={'ab ' + parseDate(props.startDate)}
                                sx={{ fontWeight: 800 }}
                            />
                        </Box>
                    )}
                    <Typography variant="h6">{props.title}</Typography>
                    <Typography sx={{ pr: 2 }}>{props.text}</Typography>
                </Box>
            </Box>
        </Paper>
    );
};

export default News;
