import { FC, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const SuperAdminRoute: FC = () => {
    const context = useContext(AppContext);

    return context.isSuperadmin ? <Outlet /> : <Navigate to="/" />;
};

export default SuperAdminRoute;
