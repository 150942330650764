import { LoginUser } from '../../types';
import { axiosInstance } from '../../utils/api/api-utils';

export default class LoginApi {
    static login(body: LoginUser): Promise<any> {
        return axiosInstance.post('/api/auth/login', body).then((res) => {
            return res.data;
        });
    }
}
