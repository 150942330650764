import { Box } from '@mui/system';
import { FC, useContext, useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { Regions } from '../../types/region';
import { AppContext } from '../../hooks/context';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import UserSelection from './users-selection';

interface FilterToolbarProps {
    regionFilter: string;
    setRegionFilter: (v: string) => void;
    selectedMonth: moment.Moment;
    setSelectedMonth: (month: moment.Moment) => void;
    userFilter: { username: string; id: string }[];
    setUserFilter: (userId: { username: string; id: string }[]) => void;
}

const FocusPartnerUserFilterToolbar: FC<FilterToolbarProps> = (
    props: FilterToolbarProps,
) => {
    const {
        regionFilter,
        setRegionFilter,
        selectedMonth,
        setSelectedMonth,
        userFilter,
        setUserFilter,
    } = props;
    const context = useContext(AppContext);

    const [regionFilterOptions, setRegionFilterOptions] = useState<
        JSX.Element[]
    >([]);

    useEffect(() => {
        if (context.isAdmin) {
            const options: JSX.Element[] = [];
            options.push(
                <MenuItem key="DefaultRegion" value="">
                    <ListItemText primary="Region wählen" />
                </MenuItem>,
            );
            let r: string[] = Object.keys(Regions);
            r.forEach((s) => {
                options.push(
                    <MenuItem key={s} value={s}>
                        <ListItemText primary={Regions[s]} />
                    </MenuItem>,
                );
            });
            setRegionFilterOptions(options);
        }
    }, [context.isAdmin]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box>
                <Box
                    display="flex"
                    sx={{
                        p: 1,
                        width: '50rem',
                        flexDirection: { xs: 'column', sm: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            width: '22rem',
                            flex: 1,
                            mr: { sm: 5 },
                            mt: { xs: 2 },
                        }}
                    >
                        <DatePicker
                            label="Fokuspartner Monat"
                            onChange={(v) => {
                                if (v) setSelectedMonth(v);
                            }}
                            inputFormat="MM.YYYY"
                            views={['year', 'month']}
                            openTo="month"
                            value={selectedMonth}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    sx={{ width: '22rem' }}
                                    {...params}
                                />
                            )}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '22rem',
                            flex: 1,
                            mr: { sm: 5 },
                            mt: { xs: 2 },
                        }}
                    >
                        <FormControl sx={{ width: '22rem' }}>
                            <InputLabel>Region</InputLabel>
                            <Select
                                value={regionFilter}
                                label="Region"
                                onChange={(e) =>
                                    setRegionFilter(e.target.value)
                                }
                            >
                                {regionFilterOptions}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            width: '22rem',
                            flex: 1,
                            mr: { sm: 5 },
                            mt: { xs: 2 },
                        }}
                    >
                        <UserSelection
                            userFilter={userFilter}
                            setUserFilter={setUserFilter}
                        />
                    </Box>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default FocusPartnerUserFilterToolbar;
