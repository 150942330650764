export enum FieldType {
    TEXT,
    SELECT,
    SELECT_REGIONS,
    USER_SELECT,
}

export interface Field {
    key: string;
    label: string;
    type: FieldType;
    values?: string[];
    valueType?: object;
    notEditable?: boolean;
    nonAdminEditable?: boolean;
    value?: string;
    multiline?: boolean;
    hideNonAdmin?: boolean;
    hideIfUndefined?: boolean;
    emptyIfRemoteValStartsWith?: string;
    editableIfRemoteValStartsWith?: string;
    onlyIfStatus?: string[];
    valueFormatter?: Function;
    hideNonRegionLeadAndAdmin?: boolean;
}

export enum FieldNames {
    combinedName = 'Name',
    salutation = 'Anrede',
    firstName = 'Vorname',
    lastName = 'Nachname',
    contractNumber = 'Vertragsnummer',
    region = 'Region',
    orderNumber = 'Bestellnummer',
    orderDate = 'Bestelldatum',
    customerNumber = 'Kundennummer',
    executionDate = 'Ausführungstermin',
    street = 'Straße',
    city = 'Stadt',
    zip = 'Postleitzahl',
    email = 'E-Mail Adresse',
    phone = 'Telefonnummer',
    callbackPhone = 'Rückrufnummer',
    birthday = 'Geburtsdatum',
    comment = 'Kommentar',
    bkto = 'BKTO',
    inquiry = 'Rückfrage',
    inquiryAnswer = 'Rückfrage beantwortet',
}
