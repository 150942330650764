import { axiosInstance } from '../../utils/api/api-utils';

export default class ContractApi {
    static persistContract(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/contracts/persistContract', body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static persistEmail(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/contracts/persistEmail', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static sendContractEmail(
        token: string | undefined,
        body: any,
    ): Promise<any> {
        return axiosInstance
            .post('/api/contracts/sendContractEmail', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getContracts(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/contracts', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static exportContracts(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/contracts/export', body, {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            })
            .then((res) => {
                return res.data;
            });
    }

    static getNextNewContract(
        token: string | undefined,
        region?: string | null,
    ): Promise<any> {
        return axiosInstance
            .get(
                `/api/contracts/nextNewContract${region ? `/${region}` : ''}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                },
            )
            .then((res) => {
                return res.data;
            });
    }

    static startExport(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/contracts/export', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getExports(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/contracts/export/list', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getContract(
        token: string | undefined,
        contractId: string,
    ): Promise<any | undefined> {
        return axiosInstance
            .get(`/api/contracts/contract/${contractId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static editContract(
        token: string | undefined,
        contractId: string,
        body: any,
    ): Promise<any> {
        return axiosInstance
            .post(`/api/contracts/contract/${contractId}`, body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static addMessage(
        token: string | undefined,
        contractId: string,
        body: any,
    ): Promise<any> {
        return axiosInstance
            .post(`/api/contracts/contract/${contractId}/message`, body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static deleteContract(
        token: string | undefined,
        contractId: string,
    ): Promise<any> {
        return axiosInstance
            .delete(`/api/contracts/contract/${contractId}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static sendContractInquiryEmail(
        token: string | undefined,
        contractId: string,
        body: any,
    ): Promise<any | undefined> {
        return axiosInstance
            .post(`/api/contracts/contract/${contractId}/inquiry`, body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }
}
