import { Box, Typography } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../hooks/context';

interface HeaderProps {
    title?: string;
    subTitle?: string;
    m?: number;
}

const regionLogos = {
    SOUTHEAST: 'sales-con-logo',
    WEST: 'bt-sales-logo',
    NORTHEAST: 'con-corp-logo',
    TELENEXUS: 'telenexus-logo',
};

const Header: FC<HeaderProps> = (props) => {
    const context = useContext(AppContext);
    const [logo, setLogo] = useState<JSX.Element | JSX.Element[]>();
    const logoNames = Object.values(regionLogos);
    const [region, setRegion] = useState<string | undefined>('');

    useEffect(() => {
        if (
            context.isAuthenticated &&
            (context.region !== region || (!context.region && !logo))
        ) {
            setRegion(context.region);
            if (context.region && typeof context.region === 'string') {
                if (regionLogos[context.region]) {
                    setLogo(getLogo(regionLogos[context.region]));
                }
            } else if (!context.region && context.isAdmin) {
                setLogo(logoNames.map((l) => getLogo(l)));
            }
            setLogo(getLogo(regionLogos.NORTHEAST));
        }
    }, [
        context.region,
        context.isAdmin,
        context.isAuthenticated,
        logoNames,
        region,
        setRegion,
    ]);

    const getLogo = (logoName: string): JSX.Element => {
        return (
            <img
                height="45"
                style={{ marginLeft: 5, marginRight: 5 }}
                key={logoName}
                src={`https://storage.googleapis.com/con-corp/assets/${logoName}.png`}
                alt="Logo"
            />
        );
    };

    return (
        <Box sx={{ m: props.m, mt: 0 }} display="flex">
            <Box>{logo}</Box>
            <Box sx={{ mt: 'auto', mb: 'auto', ml: 2 }}>
                <Typography
                    variant="h5"
                    component="h5"
                    color="#000"
                    fontWeight="bold"
                >
                    {props.title}
                    <Typography color="#000" sx={{ lineHeight: 0.5 }}>
                        {props.subTitle}
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
};

export default Header;
