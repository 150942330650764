import React, { createContext, useEffect, useState } from 'react';
import { IAppContext } from '../types';
import { authCookieName } from '../utils/constants';
import { ContractDoc } from '../types/contract';
import { TokenData } from '../types/token-data';
import { decodeToken } from 'react-jwt';
import {
    getCookie,
    setCookie,
    deleteCookie,
} from '../utils/cookie/cookie-utils';
import { Role } from '../types/role';

const authToken = getCookie(authCookieName);
const isAuthenticated = authToken !== undefined;

const user: TokenData | null = decodeToken(authToken || '');

const initialContext: IAppContext = {
    authToken,
    user,
    isAuthenticated,
    // user: undefined,
    // userLoading: true,
    // userDocLoading: true,
    isAdmin: false,
    isSuperadmin: false,
    role: undefined,
    selectedContractId: undefined,
    setSelectedContractId: (id: string | undefined) => console.log(id),
    selectedContract: undefined,
    setSelectedContract: (contract: ContractDoc | undefined) =>
        console.log(contract),
    authenticate: (authToken: string) => console.log(authToken),
    setUser: (user: TokenData | null | undefined) => console.log(user),
    region: undefined,
    setRegion: (r: string | string[]) => console.log(r),
    setRole: (role: string) => console.log(role),
    signout: () => console.log('Signed out'),
};

export const AppContext = createContext<IAppContext>(initialContext);

const AppProvider = (props: { children: React.ReactNode }) => {
    const [authToken, setAuthToken] = useState(initialContext.authToken);
    const [isAuthenticated, setIsAuthenticated] = useState(
        initialContext.isAuthenticated,
    );
    const [user, setUser] = useState(initialContext.user);
    const [region, setRegion] = useState<string | undefined>();
    const [selectedContractId, setSelectedContractId] = useState<string>();
    const [selectedContract, setSelectedContract] = useState<ContractDoc>();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperadmin, setIsSuperAdmin] = useState(false);
    const [role, setRole] = useState<Role | undefined>(undefined);

    useEffect(() => {
        const userData: TokenData | null = decodeToken(authToken || '');
        if (userData) {
            if (userData.role) {
                if (userData.role === 'SUPERADMIN') {
                    setIsAdmin(true);
                    setIsSuperAdmin(true);
                } else if (userData.role === 'ADMIN') {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
                setRole(userData.role);
            }
            if (userData.region) {
                setRegion(userData.region.toString());
            }
        }
        setUser(userData);
    }, [authToken]);

    const authenticate = (authToken: string) => {
        setCookie(authCookieName, authToken);
        setIsAuthenticated(true);
        setAuthToken(authToken);
    };

    const signout = () => {
        deleteCookie(authCookieName);
        setAuthToken(undefined);
        setIsAuthenticated(false);
        setIsAdmin(false);
        setIsSuperAdmin(false);
        setRegion(undefined);
    };

    return (
        <AppContext.Provider
            value={{
                ...initialContext,
                isAuthenticated,
                // user,
                // userLoading,
                // userDocLoading,
                isAdmin,
                isSuperadmin,
                role,
                selectedContractId,
                setSelectedContractId,
                selectedContract,
                setSelectedContract,
                authToken,
                user,
                setUser,
                authenticate,
                signout,
                region,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppProvider;
